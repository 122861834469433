import React, { useEffect, useState } from 'react';
import { Card, Spinner } from "reactstrap";
import { useDispatch ,useSelector} from "react-redux";
import ReactPaginate from "react-paginate";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import { GetSurveyFormThunk } from '../../redux/SurveyFormSlice.js/GetSurveyFormSlice';

const SurveyForm = () => {
    const dispatch = useDispatch();
    const { loading, success, error, errorMessage, SurveyFormData } = useSelector((state)=> state.surveyForm.getSurveyForm);

    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");

    // ********************************** Search and Pagination start ***********************************//

    const formsPerPage = 10;
    const [allforms, setAllForms] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    console.log('allforms', allforms)
    const pagesVisited = pageNumber * formsPerPage;
    const pageCount = Math.ceil(
        allforms.filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                // listing.postalCode.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.phoneNumber?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.email?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.fullName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.packageFlow?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.creationDate?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        }).length / formsPerPage
    );

    const displayListing = allforms
        .filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                // listing.postalCode.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.phoneNumber?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.email?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.fullName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.packageFlow?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.creationDate?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + formsPerPage);

    useEffect(() => {
        setAllForms(SurveyFormData);
    }, [SurveyFormData]);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    // Change Page
    const paginate = ({ selected }) => setPageNumber(selected);

    // ********************************** Pagination End ***********************************//


    useEffect(() => {
        dispatch(GetSurveyFormThunk());
    }, [dispatch]);


    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };

    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Head title="User Created | Admin Portal"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Survey Forms</BlockTitle>
                            {/* <BlockDes className="text-soft">
                                <p>You have total {droppedStudentsData?.length} students.</p>
                            </BlockDes> */}
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                        <Spinner />
                    </div>
                ) : (
                    <Block>
                        <Card className="card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title">Survey Forms</h5>
                                        </div>
                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar">
                                                <li>
                                                    <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                        <Icon name="search"></Icon>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                            <div className="search-content">
                                                <Button
                                                    className="search-back btn-icon toggle-search"
                                                    onClick={() => {
                                                        setSearchText("");
                                                        toggle();
                                                    }}
                                                >
                                                    <Icon name="arrow-left"></Icon>
                                                </Button>
                                                <input
                                                    type="text"
                                                    className="form-control border-transparent form-focus-none"
                                                    placeholder="Search"
                                                    value={onSearchText}
                                                    onChange={(e) => onFilterChange(e)}
                                                />
                                                <Button className="search-submit btn-icon">
                                                    <Icon name="search"></Icon>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-inner p-0">
                                    <table className="table table-tranx">
                                        <thead>
                                            <tr className="tb-tnx-head">
                                            <th className="tb-tnx-id">
                                                    <span className="">Student Name</span>
                                                </th>
                                                <th className="tb-tnx-id">
                                                    <span className="">Other</span>
                                                </th>
                                                <th className="tb-tnx-info">
                                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                        <span>A Friend/ family member</span>
                                                    </span>
                                                    <span className="tb-tnx-date d-md-inline-block d-none">
                                                        <span className="d-none d-md-block">
                                                            <span>Google</span>
                                                            <span>Facebook</span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th className="tb-tnx-amount is-alt">
                                                    <span className="tb-tnx-total">Tiktok</span>
                                                    <span className="tb-tnx-status d-none d-md-inline-block">Reddit</span>
                                                </th>
                                                <th className="tb-tnx-id">
                                                    <span className="">An ad at driving test center</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayListing?.length > 0
                                                ? displayListing?.map((droppedStudent, index) => {
                                                    return (
                                                        <tr key={droppedStudent?._id} className="tb-tnx-item">
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{droppedStudent?.studentName}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{droppedStudent?.surveyData[6].value}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-info">
                                                                <div className="tb-tnx-desc">
                                                                    <span className={`title tb-tnx-status badge badge-dot badge-${droppedStudent?.surveyData[0].value ? "success" : "danger"}`}>{droppedStudent?.surveyData[0].value ? "True": "False"}</span>
                                                                </div>
                                                                <div className="tb-tnx-date">
                                                                    <span className={`date tb-tnx-status badge badge-dot badge-${droppedStudent?.surveyData[1].value ? "success" : "danger"}`}>{droppedStudent?.surveyData[1].value ? "True": "False"}</span>
                                                                    <span className={`date tb-tnx-status badge badge-dot badge-${droppedStudent?.surveyData[2].value ? "success" : "danger"}`}>{droppedStudent?.surveyData[2].value ? "True": "False"}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-amount is-alt">
                                                                <div className="tb-tnx-total" >
                                                                    <span className={`amount badge badge-dot badge-${droppedStudent?.surveyData[3].value ? "success" : "danger"}`}>{droppedStudent?.surveyData[3].value ? "True": "False"}</span>
                                                                </div>
                                                                <div className={` tb-tnx-status badge badge-dot badge-${droppedStudent?.surveyData[4].value ? "success" : "danger"}`}>
                                                                    {droppedStudent?.surveyData[4].value ? "True": "False"}
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span
                                                                        className={`badge badge-dot badge-${droppedStudent?.surveyData[5].value ? "success" : "danger"}`}
                                                                    >
                                                                        {droppedStudent?.surveyData[5].value ? "True": "False"}
                                                                    </span>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    );
                                                })
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-inner">
                                    {displayListing?.length > 0 ? (
                                        <ReactPaginate
                                            breakLabel="..."
                                            breakClassName="pagination-btns"
                                            onPageChange={(number) => paginate(number)}
                                            // onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={pageCount}
                                            previousLabel="<<  Prev "
                                            renderOnZeroPageCount={null}
                                            nextLabel="Next >>"
                                            previousClassName="pagination-next"
                                            nextClassName="pagination-next"
                                            activeClassName="pagination-active"
                                            pageClassName="pagination mt-0"
                                            pageLinkClassName="pagination-btns"
                                            containerClassName="pagination justify-content-start"
                                        />
                                    ) : (
                                        <div className="text-center">
                                            <span className="text-silent">No data found</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </Block>
                )}

            </Content>
        </React.Fragment>
    )
}

export default SurveyForm;
