import React from 'react';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon } from '../../components/Component';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ResetUpdateStudentNotificationData, UpdateStudentNotificationThunk } from '../../redux/ConfigurationSlice/UpdateStudentnotificationSlice';
import toast from 'react-hot-toast';
import { GetStudentNotificationThunk } from '../../redux/ConfigurationSlice/GetStudentNotificationSlice';

const NotificationModal = ({ modal, setModal, notificationData }) => {
    const dispatch = useDispatch();
    const { loading, success, error, errorMessage } = useSelector((state) => state.configuration.updateStudentNotification)

    const handleStudentNotification = (e) => {
        e.preventDefault();
        dispatch(UpdateStudentNotificationThunk({ field: notificationData.field, value: notificationData.value }))
    }

    if (success) {
        toast.success("Updated Successfully.");
        dispatch(ResetUpdateStudentNotificationData());
        dispatch(GetStudentNotificationThunk());
        setModal({ studentNotification: false });
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetUpdateStudentNotificationData());
        dispatch(GetStudentNotificationThunk());
        setModal({ studentNotification: false });
    }


    return (
        <div>
            <Modal isOpen={modal.studentNotification} toggle={() => setModal({ studentNotification: false })} className="modal-dialog-centered" size="md">
                <ModalBody>
                    <div onClick={(ev) => { ev.preventDefault(); setModal({ studentNotification: false }); }} className="close cursor_pointer">
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Update Reminder Notifications </h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={handleStudentNotification}>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={loading} type="submit" color="primary" size="md" >
                                                <span>{loading ? <Spinner size="md" /> : "Update"} </span>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button onClick={(ev) => { ev.preventDefault(); setModal({ studentNotification: false }); }} className="link link-light cursor_pointer">
                                                Cancel
                                            </Button>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </div>
    )
}

export default NotificationModal;
