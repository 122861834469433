

import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector,useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { AddInstructorEventThunk, ResetAddInstructorEventData } from '../../../redux/InstructorSlice/AddInstructorEventSlice';
import { GetInstructorLessonsThunk } from '../../../redux/InstructorSlice/GetInstructorLessons';

const AddInstructorEventModal = ({ modal, setModal, instructorId }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.instructors.addInstructorEvent);

    console.log('errorMessage', errorMessage)
    const [formData, setFormData] = useState({
        title: ""
    });

    const [scheduleTime, setScheduleTime] = useState({
        startTime: moment().startOf("hour").toDate(),
        endTime: moment().add(1, "hour").startOf("hour").toDate(),
        date: moment().toDate()
      });

    const handleAddEvent = () => {
      const addOnStartHours = moment(scheduleTime.startTime).toDate().getHours();
      const addOnStartMinutes = moment(scheduleTime.startTime).toDate().getMinutes();
      const addOnEndHours = moment(scheduleTime.endTime).toDate().getHours();
      const addOnEndMinutes = moment(scheduleTime.endTime).toDate().getMinutes();

        dispatch(AddInstructorEventThunk({ 
          startTime: moment(scheduleTime.startTime)
            .startOf("year").year(2022)
            .add("hours", addOnStartHours)
            .add("minutes", addOnStartMinutes)
            .utcOffset(0, true)
            .toISOString(), 
          // endTime: moment(scheduleTime.endTime).utcOffset(0, false).startOf('minute').toISOString(),
          endTime:  moment(scheduleTime.endTime)
          .startOf("year").year(2022)
          .add("hours", addOnEndHours)
          .add("minutes", addOnEndMinutes)
          .utcOffset(0, true)
          .toISOString(),
          date: moment(scheduleTime.date).utcOffset(0, false).startOf('minute').format(), 
          title: formData.title, 
          instructorId: instructorId 
        }))
    }

    if (success) {
        toast.success("Event has been created successfully.");
        dispatch(ResetAddInstructorEventData());
        dispatch(GetInstructorLessonsThunk({ instructorId: instructorId }));
        setModal();
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetAddInstructorEventData());
        dispatch(GetInstructorLessonsThunk({ instructorId: instructorId }));
        setModal();
    }

    const { errors, register, handleSubmit } = useForm();
    return (
        <React.Fragment>
          <Modal
            isOpen={modal.addEvent}
            toggle={() => setModal(false)}
            className="modal-md"
          >
            <ModalHeader tag="h4" toggle={() => setModal(false)}>
              Add Event
            </ModalHeader>
            <ModalBody>
                {/* <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                  <Spinner />
                </div> */}
                <form className="form-validate is-alter" onSubmit={handleSubmit(handleAddEvent)}>
                  <Row className="gx-4 gy-3">
                  <Col md="6">
                      <FormGroup>
                        <label className="form-label">Event Title</label>
                        <input
                          className="form-control"
                          ref={register({ required: "This field is required" })}
                          type="text"
                          name="title"
                          defaultValue={formData.title}
                          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </FormGroup>
                    </Col>


                    <Col sm="6">
                      <FormGroup>
                        <label className="form-label">Date</label>
                        <Row className="gx-2" style={{ zIndex: "2" }}>
                          <div className="w-100" style={{ zIndex: "2" }}>
                            <div className="form-control-wrap has-timepicker" style={{ zIndex: "2" }}>
                              <DatePicker
                                className="form-control date-picker"
                                style={{ zIndex: "2" }}
                                selected={scheduleTime.date}
                                onChange={(date) => setScheduleTime({ ...scheduleTime, date: date })}
                                // showTimeSelect
                                // showTimeSelectOnly
                                // timeIntervals={30}
                                // timeCaption="Time"
                                // dateFormat="h:mm aa"
                              />
                            </div>
                          </div>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <label className="form-label">Start Time</label>
                        <Row className="gx-2" style={{ zIndex: "2" }}>
                          <div className="w-100" style={{ zIndex: "2" }}>
                            <div className="form-control-wrap has-timepicker" style={{ zIndex: "2" }}>
                              <DatePicker
                                style={{ zIndex: "2" }}
                                selected={scheduleTime.startTime}
                                onChange={(date) => setScheduleTime({ ...scheduleTime, startTime: date })}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className="form-control date-picker"
                              />
                            </div>
                          </div>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <label className="form-label">End Time</label>
                        <Row className="gx-2">
                          <div className="w-100">
                            <div className="form-control-wrap has-timepicker" style={{ opacity: 3 }}>
                              <DatePicker
                                selected={scheduleTime.endTime}
                                onChange={(date) => setScheduleTime({ ...scheduleTime, endTime: date })}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className="form-control date-picker"
                              />
                            </div>
                          </div>
                        </Row>
                      </FormGroup>
                    </Col>
                    {/* <Col size="12">
                      <FormGroup>
                        <label className="form-label">Locations</label>
                        <Row className="gx-2">
                          <div className="w-100">
                            <div className="form-control-wrap">
                              <ul className="link-tidy ml-0">
                                {oldLocations?.map((location) => {
                                  return (
                                    <li>
                                      <div className="custom-control custom-control-sm custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          checked={location.isChecked}
                                          name={location?.name}
                                          onClick={(e) => {
                                            const difLocation = oldLocations.map((oldLoc)=>{
                                              const data = oldLoc?._id === e.target.value ? {_id:oldLoc?._id,name: oldLoc?.name, isChecked: e.target.checked }: oldLoc
                                              return data;
                                            });
                                            setOldLocations(difLocation);
                                            e.target.checked
                                            ? setSelectedLocations( selectedLoc => [...selectedLoc, location?._id])
                                            : setSelectedLocations(selectedLoc => {
                                              const updatedSelectedLoc = [...selectedLoc, location._id];
                                              const uniqueSelectedLoc = updatedSelectedLoc.filter((value, index, self) => {
                                                return self.indexOf(value) === index;
                                              });
                                              return uniqueSelectedLoc;
                                            })
                                            }}
                                          value={location?.name}
                                          id={location?.name}
                                        />
                                        <label className="custom-control-label" htmlFor={location?.name}>
                                          {location?.name}
                                        </label>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </Row>
                      </FormGroup>
                    </Col> */}
                    <Col size="12">
                      <ul className="d-flex justify-content-between gx-4 mt-1">
                        <li>
                          <Button disabled={loading} type="submit" color="primary">
                            {loading ? <Spinner /> : "Add Event"}
                          </Button>
                        </li>
                        <li>
                          <Button
                            color="#ffffff"
                            className="btn-dim"
                            onClick={() => setModal(false)}
                          >
                            Cancel
                          </Button>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </form>
            </ModalBody>
          </Modal>
        </React.Fragment>
    )
}

export default AddInstructorEventModal;