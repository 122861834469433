import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";

import Homepage from "../pages/Homepage";


import Students from "../pages/students";
import StudentsList from "../pages/students/StudentsList";
import StudentProfile from "../pages/students/StudentPersonalInformation";
import Instructors from "../pages/instructors";
import InstructorsList from "../pages/instructors/InstructorsList";
import PickupPoints from "../pages/pickupLocations";
import UnservedStudents from "../pages/UnservedStudents";
import ChangeAdminPassword from "../pages/ChangeAdminPassword";
import Promotions from "../pages/Promotions";
import UserCreated from "../pages/userCreated";
import LMSstudent from "../pages/LMSstudent/LMSstudent";
import Configuration from "../pages/Configuration";
import AddGeoMap from "../pages/MapGeoFencing/AddGeoMap";
import GeoFencing from "../pages/MapGeoFencing/GeoFencing";
import EditGeoFencing from "../pages/MapGeoFencing/EditGeoFencing";
import Invoices from "../pages/Invoices";
import ViewAllGeoMap from "../pages/MapGeoFencing/ViewAllGeoFence/ViewAllGeoFence";
import ViewInCarSheet from "../pages/students/In-CarSheet/ViewInCarSheet";
import AddInCarSheet from "../pages/students/In-CarSheet/AddInCarSheet";
import SurveyForm from "../pages/SurveyForm";
import InCarSheet from "../pages/InCarSheet";
import InstructorCancelledLesson from "../pages/InstructorCancelledLesson";
import InstructorDevices from "../pages/InstructorDevices";
const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Panel */}

        <Route exact path={`/change-admin-password`} component={ChangeAdminPassword} />
        <Route exact path={`/promotions`} component={Promotions} />

        {/* ******************* Student Routes ***************** */}

        <Route exact path={`/students`} component={StudentsList} />
        <Route exact path={`/students/:studentId`} component={Students} />
        <Route exact path={`/student-profile/`} component={Students} />
        <Route exact path={`/student-instructor/`} component={Students} />
        <Route exact path={`/student-lessons/`} component={Students} />
        <Route exact path={`/student-roadTest/`} component={Students} />
        <Route exact path={`/student-reviews/`} component={Students} />

        {/* ******************* Pickup Location Routes ***************** */}

        <Route exact path={`/pickup-location/`} component={PickupPoints} />

        {/* ******************* Invoice Routes ***************** */}

        <Route exact path={`/invoices/`} component={Invoices} />

        {/* ******************* Instructor Routes ***************** */}

        <Route exact path={`/instructors`} component={InstructorsList} />
        <Route exact path={`/instructors/:instructorId`} component={Instructors} />
        <Route exact path={`/instructors-student/`} component={Instructors} />
        <Route exact path={`/instructor-lessons/`} component={Instructors} />
        <Route exact path={`/instructor-pickup/`} component={Instructors} />
        <Route exact path={`/instructor-schedule/`} component={Instructors} />

        <Route exact path={`/unserved-students/`} component={UnservedStudents} />

        <Route exact path={`/user-created/`} component={UserCreated} />

        <Route exact path={`/lms-students/`} component={LMSstudent} />

        <Route exact path={`/configuration/`} component={Configuration} />

        <Route exact path={`/geo_fencing/`} component={GeoFencing} />
        <Route exact path={`/view_all_geo_fencing/`} component={ViewAllGeoMap} />
        <Route exact path={`/add_geo_fencing/`} component={AddGeoMap} />
        <Route exact path={`/edit_geo_fencing/`} component={EditGeoFencing} />


        <Route exact path={`/survey-forms`} component={SurveyForm} />
        <Route exact path={`/in_car_sheet`} component={InCarSheet} />
        <Route exact path={`/instructor-cancelled-lessons`} component={InstructorCancelledLesson} />

        <Route exact path={`/instructor-devices`} component={InstructorDevices} />

        {/* ************************* TESTING ROUTE *************************  */}
        <Route exact path={`/testing_route/`} component={AddInCarSheet} />

        <Route exact path={`/`} component={Homepage} />
        <Route component={RedirectAs404} />
      </Switch>
    </Suspense>
  );
};
export default Pages;
