import { combineReducers } from "@reduxjs/toolkit/dist";
import AddStudentLessonsSlice from "./AddStudentLessonsSlice";
import DeleteStudentLessonSlice from "./DeleteStudentLessonSlice";
import GetLessonDetailSlice from "./GetLessonDetailSlice";
import RescheduleLessonSlice from "./RescheduleLessonSlice";
import ScheduleLessonSlice from "./ScheduleLessonSlice";
import ScheduleRoadTestSlice from "./ScheduleRoadTestSlice";
import UnScheduleLessonSlice from "./UnScheduleLessonSlice";
import ChangeStudentLessonPickupLocationSlice from "./ChangeStudentLessonPickupLocationSlice";
import ChangeLessonTypeSlice from "./ChangeLessonTypeSlice";
import ChangeLessonStatusSlice from "./ChangeLessonStatusSlice";
import ChangeLessonExpiryDateSlice from "./ChangeLessonExpiryDateSlice";
import VerifyStripeIdSlice from "./VerifyStripeIdSlice";
import ChangeLessonInstructorSlice from "./ChangeLessonInstructorSlice";
import MarkLessonAsMissedSlice from "./MarkLessonAsMissedSlice";

export const LessonsReducers = combineReducers({
  addStudentLessons: AddStudentLessonsSlice,
  getLessonDetail: GetLessonDetailSlice,
  scheduleLesson: ScheduleLessonSlice,
  unScheduleLesson: UnScheduleLessonSlice,
  reScheduleLesson: RescheduleLessonSlice,
  deleteLesson: DeleteStudentLessonSlice,
  scheduleRoadTest: ScheduleRoadTestSlice,
  changeStudentPickupLocation: ChangeStudentLessonPickupLocationSlice,
  changeLessonType: ChangeLessonTypeSlice,
  changeLessonStatus: ChangeLessonStatusSlice,
  changeLessonExpiryDate: ChangeLessonExpiryDateSlice,
  verifyStripePID: VerifyStripeIdSlice,
  changeLessonInstructor: ChangeLessonInstructorSlice,
  markLessonAsMissed: MarkLessonAsMissedSlice
});
