import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardText, Col, Row, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Icon } from '../../../components/Component';
import { GetLessonSheetDetailThunk } from '../../../redux/StudentsSlice/InCarSheetSlice/GetLessonSheetDetailSlice';
import 'react-range-slider-input/dist/style.css';

const ShowSelectedInCarSheet = ({ page, setPage, selectedLessonSheetId }) => {
    const dispatch = useDispatch();

    const {loading, lessonSheetDetailData } = useSelector((state)=> state.Students.InCarSheet.getLessonSheetDetail )

    console.log('lessonSheetDetailData', lessonSheetDetailData)
    const [selectedCards, setSelectedCards] = useState([]);

    const toggleCardSelection = (cardId) => {
        if (selectedCards.includes(cardId)) {
            setSelectedCards(selectedCards.filter(id => id !== cardId));
        } else {
            setSelectedCards([...selectedCards, cardId]);
        }
    };

    useEffect(()=>{
        dispatch(GetLessonSheetDetailThunk({ lessonSheetId: selectedLessonSheetId }));
    },[]);

    return (
        <React.Fragment>
            <Row className="d-flex justify-content-between ">
            <Col sm="6">
                    <div className='d-flex justify-content-between'>
                        <div className=' mb-5'>
                            <Button className='btn-dim' color="primary" onClick={()=> setPage({showSelectedInCarSheet: false, view: true })}> 
                                <Icon name="back-arrow-fill"/>
                                    Back
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col sm="6">
                    <div className='d-flex justify-content-end'>
                        <div className=' mb-5'>
                            <Button className='btn btn-primary' color="primary" onClick={()=> setPage({edit: true, showSelectedInCarSheet: false })}> 
                                Edit In-Car Sheet
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            {loading ? 
                <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <Spinner />
                </div>
            : 
            <>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Icon color="primary" name="calendar-alt"/>
                        <span>Lesson Date: {moment.utc(lessonSheetDetailData?.lessonSheet?.dateTime).format("DD/MM/YYYY")}</span>
                    </div>
                    <div>Completed</div>
                </div>
                <h6>Topics</h6>
                {lessonSheetDetailData?.lessonSheet?.multiLessonSheet ? 
                    <>
                        {lessonSheetDetailData?.lessonSheet?.maneuverTopics?.map(maneuver => (
                            <Card key={maneuver._id} className={`mt-2 `} onClick={() => toggleCardSelection(maneuver._id)}>
                                <CardBody>
                                    <div className='d-flex justify-content-between'>
                                        <h6>{maneuver?.maneuverName}</h6>
                                    </div>
                                    <CardText>
                                        <div>Grade: {maneuver?.grade}</div>
                                    </CardText>
                                </CardBody>
                            </Card>
                        ))}
                        <Row className='mt-3 mb-3'>
                            <Col md="12">
                                <label className="form-label">Notes</label>
                                <p> {lessonSheetDetailData?.lessonSheet?.maneuverNotes} </p>
                            </Col>
                        </Row>
                        {Object.keys(lessonSheetDetailData?.lessonSheet?.roadTestTopics).map((roadTestTopic)=>{
                            return (
                                <div className='mb-3'>
                                    <Card className=''>
                                        <CardBody>
                                            <h6 className='mb-4'>{roadTestTopic}</h6>
                                            {lessonSheetDetailData?.lessonSheet?.roadTestTopics[roadTestTopic]?.map((question) => {
                                                return (
                                                    <CardText>
                                                        <div>{question?.maneuverName}</div>
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='selected-radio-button-main ' style={question?.grade === 0 ? {color: "white", backgroundColor: "red"}: {color: "red"}}>
                                                                    <div className='selected-radio-button' >
                                                                        xx
                                                                    </div>
                                                                </div>
                                                                <div className='selected-radio-button-main ' style={question?.grade === 1 ? {color: "white", backgroundColor: "orange"}: {color: "orange"}}>
                                                                    <div className='selected-radio-button'>
                                                                        x
                                                                    </div>
                                                                </div>
                                                                <div className='selected-radio-button-main ' style={question?.grade === 2 ? {color: "white", backgroundColor: "green"}: {color: "green"}}>
                                                                    <div className='selected-radio-button' >
                                                                        ok
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardText>
                                                )
                                            })}
                                        </CardBody>
                                    </Card>
                                </div>
                            )
                        })}
                        <span> <span style={{fontWeight: "bold"}}>Overall Grade: </span>  {lessonSheetDetailData?.lessonSheet?.overallGrade}</span>
                        <Row className='mt-3'>
                            <Col md="12">
                                <label className="form-label">Notes</label>
                                <p> {lessonSheetDetailData?.lessonSheet?.roadTestNotes} </p>
                            </Col>
                        </Row>

                    </>
                :
                lessonSheetDetailData?.lessonSheet?.simulatedRoadTest ? 
                    <>
                        {Object.keys(lessonSheetDetailData?.lessonSheet?.topics).map((category)=>{
                            return (
                                <div className='mb-3 mt-3'>
                                    <Card className=''>
                                        <CardBody>
                                            <h6 className='mb-4'>{category}</h6>
                                            {lessonSheetDetailData?.lessonSheet?.topics[category]?.map((question) => {
                                                return (
                                                    <CardText>
                                                        <div>{question?.maneuverName}</div>
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='selected-radio-button-main ' style={question?.grade === 0 ? {color: "white", backgroundColor: "red"}: {color: "red"}}>
                                                                    <div className='selected-radio-button' >
                                                                        xx
                                                                    </div>
                                                                </div>
                                                                <div className='selected-radio-button-main ' style={question?.grade === 1 ? {color: "white", backgroundColor: "orange"}: {color: "orange"}}>
                                                                    <div className='selected-radio-button'>
                                                                        x
                                                                    </div>
                                                                </div>
                                                                <div className='selected-radio-button-main ' style={question?.grade === 2 ? {color: "white", backgroundColor: "green"}: {color: "green"}}>
                                                                    <div className='selected-radio-button' >
                                                                        ok
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardText>
                                                )
                                            })}
                                        </CardBody>
                                    </Card>
                                </div>
                            )
                        })} 
                        <span> <span style={{fontWeight: "bold"}}>Overall Grade: </span>  {lessonSheetDetailData?.lessonSheet?.overallGrade}</span>
                        <Row className='mt-3'>
                            <Col md="12">
                                <label className="form-label">Notes</label>
                                <p> {lessonSheetDetailData?.lessonSheet?.notes} </p>
                            </Col>
                        </Row>
                    </>
                : 
                    <>
                        {lessonSheetDetailData?.lessonSheet?.topics?.map(maneuver => (
                            <Card key={maneuver._id} className={`mt-2 `} onClick={() => toggleCardSelection(maneuver._id)}>
                                <CardBody>
                                    <div className='d-flex justify-content-between'>
                                        <h6>{maneuver?.maneuverName}</h6>
                                    </div>
                                    <CardText>
                                        <div>Grade: {maneuver?.grade}</div>
                                    </CardText>
                                </CardBody>
                            </Card>
                        ))}
                        <Row className='mt-3'>
                            <Col md="12">
                                <label className="form-label">Notes</label>
                                <p> {lessonSheetDetailData?.lessonSheet?.notes} </p>
                            </Col>
                        </Row>
                    </>
                }
            </>
        }

        </React.Fragment>
    )
}

export default ShowSelectedInCarSheet;