import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetAllRegionsThunk = createAsyncThunk("GetAllRegions/GetAllRegionsThunk", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `${ApiEndpoints.Configuration.GetAllRegions}`,
            {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            }
        );
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
}
);

const GetAllRegionsSlice = createSlice({
    name: "GetAllRegions",
    initialState: {
        getAllRegionsData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetAllRegionsData: (state, action) => {
            state.getAllRegionsData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },

    extraReducers: {
        [GetAllRegionsThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetAllRegionsThunk.fulfilled]: (state, action) => {
            state.getAllRegionsData = action.payload.regions;
            state.success = true;
            state.loading = false;
        },
        [GetAllRegionsThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.getAllRegionsData = [];
        },
    },
});

export default GetAllRegionsSlice.reducer;

export const { ResetGetAllRegionsData } = GetAllRegionsSlice.actions;

export { GetAllRegionsThunk };
