import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardText, Col, Row, Spinner } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { GetAllInCarSheetManeuversThunk, ResetGetAllInCarSheetManeuversData } from '../../../redux/StudentsSlice/InCarSheetSlice/GetAllInCarSheetManeuversSlice';
import { GetLessonSheetDetailThunk } from '../../../redux/StudentsSlice/InCarSheetSlice/GetLessonSheetDetailSlice';

const EditInCarSheet = ({ page, setPage, isEditSheet, setIsEditSheet, selectedTopics, setSelectedTopics, setIsMultiInCarSheet, setIsManeuverSelection, selectedLessonSheetId }) => {
    const dispatch = useDispatch();
    const {studentId} = useParams();

    const { loading, GetAllInCarSheetManeuversData} = useSelector((state)=> state.Students.InCarSheet.getAllInCarSheetManeuvers)
    const {loading: lessonSheetLoading, lessonSheetDetailData } = useSelector((state)=> state.Students.InCarSheet.getLessonSheetDetail )

    const [selectSimulatedRoadTest, setSelectSimulatedRoadTest] = useState();
    const [selectedCards, setSelectedCards] = useState([]);

    const toggleCardSelection = (maneuver) => {
        if(GetAllInCarSheetManeuversData?.simulatedRoadTest._id === maneuver._id){
            if(selectSimulatedRoadTest){
                setSelectSimulatedRoadTest(undefined)
            }else{
                setSelectSimulatedRoadTest(maneuver);
            }
        } else {
            if (selectedCards.includes(maneuver._id)) {
                setSelectedCards(selectedCards.filter(id => id !== maneuver._id));
                setSelectedTopics(selectedTopics.filter(topic => topic._id !== maneuver._id))
            } else {
                setSelectedCards([...selectedCards, maneuver._id]);
                setSelectedTopics([...selectedTopics, maneuver]);
            }
        }
    };

    const handleEditTopics = (e) => { 
        e.preventDefault();
        setIsEditSheet(true)
        if(selectSimulatedRoadTest && selectedCards.length > 0 ){
            setIsManeuverSelection({ isBoth: true });
            setIsMultiInCarSheet(true);
            setPage({ edit: false, showSelectedTopic: true });
        } 
        if(!selectSimulatedRoadTest) {
            setIsManeuverSelection({ isTopic: true });
            setIsMultiInCarSheet(false);
            setPage({edit: false, showSelectedTopic: true });
        }
        if(selectSimulatedRoadTest && selectedCards.length === 0){
            setIsManeuverSelection({ isRoadTest: true });
            setIsMultiInCarSheet(false);
            dispatch(ResetGetAllInCarSheetManeuversData());
            setPage({ edit: false, simulatedTestInCarSheet: true });
        }
    };

    useEffect(() => {
        dispatch(GetLessonSheetDetailThunk({ lessonSheetId: selectedLessonSheetId }));
        dispatch(GetAllInCarSheetManeuversThunk({ studentId: studentId, isRoadTest: false }));
    },[dispatch, studentId, selectedLessonSheetId]);

    useEffect(()=>{
        selectedTopics?.forEach((topic) => {
            if (!selectedCards.includes(topic._id)) {
                setSelectedCards(prevSelectedCards => [...prevSelectedCards, topic._id])
            }
        })
    }, []);

    useEffect(() => {
        if(GetAllInCarSheetManeuversData?.maneuvers?.length > 0) {
            const preSelectedCards = GetAllInCarSheetManeuversData?.maneuvers?.filter(maneuver => {
                const relevantTopics = lessonSheetDetailData.lessonSheet?.simulatedRoadTest 
                    ? lessonSheetDetailData.lessonSheet?.maneuverTopics 
                    : lessonSheetDetailData.lessonSheet?.topics;
                return relevantTopics?.some(topic => topic.maneuverId === maneuver._id);
            });

            const matchedCards = preSelectedCards?.map((preSelectedCard) => {
                let matchingTopic = [];
                if(lessonSheetDetailData.lessonSheet?.simulatedRoadTest){
                    matchingTopic = lessonSheetDetailData.lessonSheet?.maneuverTopics.find(
                        topic => topic.maneuverId === preSelectedCard._id
                    );
                } else {
                    matchingTopic = lessonSheetDetailData.lessonSheet?.topics.find(
                        topic => topic.maneuverId === preSelectedCard._id
                    );
                }
                if (matchingTopic) {
                    return {
                        ...preSelectedCard,
                        previousGrade: matchingTopic.grade, 
                    };
                } else {
                    return preSelectedCard;
                }
            })
            if (lessonSheetDetailData.lessonSheet?.simulatedRoadTest) {
                setSelectSimulatedRoadTest(true);
                setSelectedTopics([...matchedCards, GetAllInCarSheetManeuversData.simulatedRoadTest] || []);
            }

            setSelectedCards(matchedCards?.map(maneuver => maneuver._id) || []);
            setSelectedTopics(matchedCards || []);
        }
    }, [GetAllInCarSheetManeuversData, lessonSheetDetailData]);

    return (
        <React.Fragment>
            <Row className="d-flex justify-content-between ">
                <Col sm="12">
                    <div className='d-flex justify-content-between'>
                        <div className=' mb-5'>
                            <Button className='btn-dim' color="primary" onClick={()=> {setPage({edit: false, showSelectedInCarSheet: true })}}> 
                                <Icon name="back-arrow-fill"/>
                                {" "} Back
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='d-flex justify-content-end'>
                <p>Outstanding</p>
            </div>
            <h6>Topics</h6>
            {loading || lessonSheetLoading ? 
                <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <Spinner />
                </div>
                : 
                <>
                    {GetAllInCarSheetManeuversData?.maneuvers?.map(maneuver => {
                        let gradeToShow;
                        if(`${typeof(lessonSheetDetailData.lessonSheet?.topics)}` === 'object') {
                            gradeToShow = maneuver.previousGrade;
                        } else {
                            const matchingTopic = lessonSheetDetailData.lessonSheet?.topics?.find(
                                (topic) => topic.maneuverName === maneuver.name
                            );
                            gradeToShow = matchingTopic ? matchingTopic.grade : maneuver.previousGrade;    
                        }

                        return ( 
                            <Card key={maneuver._id} className={`mt-2 cursor_pointer ${selectedCards.includes(maneuver._id) ? 'selected-card' : ''}`} onClick={() => toggleCardSelection(maneuver)}>
                                <CardBody>
                                    <h6>{maneuver?.name}</h6>
                                    <CardText>
                                        <div>Previous Grade: {gradeToShow ? gradeToShow: 0}</div>
                                    </CardText>
                                </CardBody>
                            </Card>
                        )
                    })}
                    <Card className={`mt-2 cursor_pointer ${selectSimulatedRoadTest ? 'selected-card' : ''}`} onClick={() => toggleCardSelection(GetAllInCarSheetManeuversData?.simulatedRoadTest)}>
                        <CardBody>
                            <h6>{GetAllInCarSheetManeuversData?.simulatedRoadTest?.name}</h6>
                            <CardText>
                                <div>Previous Grade: {GetAllInCarSheetManeuversData?.simulatedRoadTest?.previousGrade ? GetAllInCarSheetManeuversData?.simulatedRoadTest?.previousGrade: 0}</div>
                            </CardText>
                        </CardBody>
                    </Card>
                </>
            }
            <div className='w-100 d-flex justify-content-center mt-3'>
                <Button className='' onClick={handleEditTopics} >
                    Edit Topics
                </Button>
            </div>
        </React.Fragment>
    )
}

export default EditInCarSheet;
