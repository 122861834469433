import React, { useEffect, useState } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from "@reach/combobox";
import { Popover, PopoverHeader, PopoverBody, ModalHeader, Modal, ModalBody, FormGroup, Button, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { useLoadScript } from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import moment from "moment";

import { GetLessonDetailThunk } from "../../../redux/LessonsSlice/GetLessonDetailSlice";
import { Col, Row, RSelect } from "../../../components/Component";
import { setDateForPicker } from "../../../utils/Utils";
import { statusOptions } from "../../../components/partials/calender/CalenderData";
import "../instructorStyle.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


const EventView = (event) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, extendedProps, publicId } = event.event.event._def;
  return (
    <React.Fragment>
      <div id={publicId} onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
        {title}
      </div>{" "}
      <Popover placement="bottom" isOpen={mouseEnter} target={publicId}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{extendedProps.description}</PopoverBody>
      </Popover>
    </React.Fragment>
  );
};

const libraries = ["places"];
const options = {
  // styles: MapStyles,
  disableDefaultUI: true,
  zoomControl: false,
  componentRestrictions: {
    country: "ca",
  },
};

const InstructorLessonCalender = ({ lessonsDetail, onDelete, onEdit, businessHours }) => {

  console.log('businessHours', businessHours)
  const dispatch = useDispatch();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const {
    loading: getLessonDetailLoading,
    error: getLessonDetailError,
    lessondetailData,
  } = useSelector((state) => state.lessons.getLessonDetail);

  const [modalState, updateModal] = useState(false);
  const [mockEvents, updateEvents] = useState(lessonsDetail);
  const [event, updateEvent] = useState({});
  const [theme, settheme] = useState();
  const [edit, updateEditModal] = useState(false);
  const [lessonDateTime, setLessonDateTime] = useState({
    date: event.start,
    time: event.time,
  });

  useEffect(() => {
    updateEvents(lessonsDetail);
  }, [lessonsDetail]);

  const { handleSubmit } = useForm();

  const handleFormSubmit = (formData) => {
    let newEvent = {};
    newEvent = {
      id: event.id,
      className: theme.value,
      type: theme,
      title: formData.title,
      start: event.start,
      end: event.end,
      description: formData.description,
    };
    onEdit(newEvent);
    settheme("");
    toggleEdit();
  };

  const toggle = () => {
    updateModal(!modalState);
  };

  const toggleEdit = () => {
    updateEditModal(!edit);
  };

  const handleEventClick = (info) => {
    const event = lessonsDetail.find((item) => item.id === info.event._def.publicId);
    dispatch(GetLessonDetailThunk({ lessonId: event._id }));
    updateEvent(event);
    settheme(event.type);
    toggle();
  };

  const [latlng, setLatLng] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [pickupLocation, setPickUpLocation] = useState("");
  const [selected, SetSelected] = useState(false);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.653225, lng: () => -79.383186 },
      radius: 200 * 1000,
      // componentRestrictions: { country: "ca" },
    },
  });


  return (
    <React.Fragment>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin]}
            events={mockEvents}
            eventColor="#201b08"
            eventClick={(info) => handleEventClick(info)}
            initialView="timeGridWeek"
            headerToolbar={{
              left: "title prev,next",
              center: null,
              right: "today timeGridWeek,timeGridDay,listWeek",
            }}
            eventContent={(e) => (
              <div className="event-content">
                <span style={{ marginRight: "4px" }}>{e.event.extendedProps.icon}</span>
                <span>{e.event.title}</span>
              </div>
            )}
          
            businessHours={businessHours}
            allDaySlot={true}
            themeSystem="bootstrap"
            height={800}
            contentHeight={780}
            // eventContent={(e) => <EventView event={e} />}
            aspectRatio={3}
            editable={true}
            className="fc-body fc-nonbusiness"
            timeZone="cad"
          />

      <Modal isOpen={modalState} toggle={toggle} className="modal-md">
        <ModalHeader className="fc-event-info" toggle={toggle}>
          {event?.studentName && event?.studentName}
        </ModalHeader>
        <ModalBody>
          {getLessonDetailLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <Spinner />
            </div>
          ) : getLessonDetailError || Object.keys(lessondetailData).length === 0 ? (
            <p>No detail Found</p>
          ) : (
            <Row className="gy-3 py-1">
              <Col sm="6">
                <h6 className="overline-title">Start Time</h6>
                <p id="preview-event-start">
                  {lessondetailData?.lesson && moment.utc(lessondetailData?.lesson.dateTime).format("hh:mm A")}
                </p>
              </Col>
              <Col sm="6" id="preview-event-end-check">
                <h6 className="overline-title">End Time</h6>
                <p id="preview-event-end">
                  {lessondetailData?.lesson &&
                    moment.utc(lessondetailData?.lesson.dateTime).add( (lessondetailData?.lesson?.duration / 60) , "hours").format("hh:mm A")}
                </p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">City</h6>
                <p id="preview-event-description">{lessondetailData?.lesson && lessondetailData?.lesson.city}</p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">Home Pickup Location</h6>
                <p id="preview-event-description">{lessondetailData?.lesson && lessondetailData?.lesson?.homePickup}</p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">Status</h6>
                <p id="preview-event-description">{lessondetailData?.lesson && lessondetailData?.lesson.status}</p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">Type</h6>
                <p id="preview-event-description">
                  {lessondetailData?.lesson && lessondetailData?.lesson.isRoadTest ? "Road Test" : "Regular"}
                </p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">Purchase Date</h6>
                <p id="preview-event-description">
                  {lessondetailData?.lesson && lessondetailData?.lesson.purchaseDate.split("T")[0]}
                </p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">Duration</h6>
                <p id="preview-event-description">{lessondetailData?.lesson && lessondetailData?.lesson.duration}</p>
              </Col>

              <Col sm="12" id="preview-event-description-check">
                <h6 className="overline-title">Pickup Location</h6>
                <p id="preview-event-description">
                  {lessondetailData?.lesson && lessondetailData?.lesson?.pickupLocation}
                </p>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={edit} toggle={toggleEdit} className="modal-md">
        <ModalHeader toggle={toggleEdit}>Edit Lesson</ModalHeader>
        <ModalBody>
          <form className="form-validate is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <Row className="gx-4 gy-3">
              <Col size="12">
                <FormGroup>
                  <label className="form-label" htmlFor="event-title">
                    Select Location
                  </label>
                  <div className="form-control-wrap">
                    <Combobox
                      onSelect={async (address) => {
                        setValue(address, false);
                        clearSuggestions();
                        SetSelected(true);
                        try {
                          const results = await getGeocode({ address });
                          const { lat, lng } = await getLatLng(results[0]);
                          setLatLng({
                            latitude: lat,
                            longitude: lng,
                          });
                          setPickUpLocation(address);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <div className="map-input-search" style={{ position: "relative", zIndex: 1 }}>
                        <ComboboxInput
                          style={{
                            borderColor: "black",
                            padding: "15px 12px",
                            width: "100%",
                            textAlign: "left",
                            fontFamily: "inherit",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "18px",
                            color: "#071d27",
                            outline: "none",
                            // border: "none",
                          }}
                          className="map-input form-control"
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          disabled={!ready}
                          autocomplete={false}
                          placeholder="Enter an address"
                        />
                      </div>
                      <ComboboxPopover>
                        <div className={selected ? "display-none" : "container"}>
                          <div className="row">
                            <div className="no-padding-mobile">
                              <ComboboxList
                                className="map-box map-box-suggestions"
                                style={{
                                  background: "#ffffff",
                                  border: "1px solid #dfe5e8",
                                  boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.14)",
                                  borderRadius: "4px",
                                  padding: "15px",
                                  marginLeft: "15px",
                                  position: "relative",
                                  zIndex: "50000",
                                }}
                              >
                                {status === "OK" &&
                                  data.map(({ place_id, description }) => (
                                    <>
                                      <ComboboxOption
                                        style={{ cursor: "pointer" }}
                                        key={place_id}
                                        value={description}
                                      />
                                      <hr />
                                    </>
                                  ))}
                              </ComboboxList>
                            </div>
                          </div>
                        </div>
                      </ComboboxPopover>
                    </Combobox>
                  </div>
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup>
                  <label className="form-label">Start Date &amp; Time</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={new Date(event.start)}
                          onChange={(date) => updateEvent({ ...event, start: setDateForPicker(date) })}
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={new Date(event.time)}
                          onChange={(date) => setLessonDateTime({ ...lessonDateTime, startTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Row>
                </FormGroup>
              </Col>
              {/* <Col sm="6">
                <FormGroup>
                  <label className="form-label">End Date &amp; Time</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={new Date(event.end)}
                          onChange={(date) => updateEvent({ ...event, end: setDateForPicker(date) })}
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={new Date(event.dateTime)}
                          onChange={(date) => setDates({ ...dates, endTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Row>
                </FormGroup>
              </Col> */}
              <Col size="12">
                <FormGroup>
                  <label className="form-label">Status</label>
                  <div className="form-control-wrap">
                    <RSelect options={statusOptions} defaultValue={event.type} onChange={(e) => settheme(e)} />
                  </div>
                </FormGroup>
              </Col>
              <Col size="12">
                <ul className="d-flex justify-content-between gx-4 mt-1">
                  <li>
                    <Button type="submit" color="primary">
                      Update Lesson
                    </Button>
                  </li>
                  <li>
                    <Button color="danger" className="btn-dim" onClick={toggleEdit}>
                      Cancel Lesson
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default InstructorLessonCalender;
