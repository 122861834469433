import { combineReducers } from "@reduxjs/toolkit";
import GetAllInvoicesSlice from "./GetAllInvoicesSlice";
import ChangeInvoiceStatusSlice from "./ChangeInvoiceStatusSlice";
import EmailInvoiceSlice from "./EmailInvoiceSlice";
import DownloadInvoiceSlice from "./DownloadInvoiceSlice";
import DownloadPerdiodInvoicesSlice from "./DownloadPeriodInvoicesSlice";
import ChangeAllInvoicesStatusSlice from "./ChangeAllInvoicesStatusSlice";


export const invoicesReducers = combineReducers({
    getAllInvoices: GetAllInvoicesSlice,
    changeInvoiceStatus: ChangeInvoiceStatusSlice,
    emailInvoice: EmailInvoiceSlice,
    downloadInvoice: DownloadInvoiceSlice,
    downloadPeriodInvoices: DownloadPerdiodInvoicesSlice,
    changeAllInvoicesStatus: ChangeAllInvoicesStatusSlice
})