import React, { useEffect, useState } from 'react'

import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { Icon, RSelect } from '../../../components/Component';
import { NumberOfLessonsOptions } from '../../pre-built/orders/OrderData';
import { AddLessonsThunk, ResetAddStudentLessonsData } from '../../../redux/LessonsSlice/AddStudentLessonsSlice';
import { ResetVerifyStripePIDData, VerifyStripePIDThunk } from '../../../redux/LessonsSlice/VerifyStripeIdSlice';
import toast from 'react-hot-toast';
import { GetStudentLessonsThunk } from '../../../redux/StudentsSlice/GetStudentLessonsSlice';

const AddLessonModal = ({ modal, setModal, countryCode }) => {
    const dispatch = useDispatch();
    const { studentId } = useParams();

    const {
        loading: studentInstructorLoading,
        studentInstructorsData,
    } = useSelector((state) => state.Students.getStudentInstructors);
    const { studentPersonalDetail } = useSelector(
        (state) => state.Students.getStudentPersonalDetail
    );
    const {
        loading: verifyStripePidLoading,
        success: verifyStripePidSuccess, 
        error: verifyStripePidError,
        errorMessage: verifyStripePidErrorMessage
    } = useSelector((state)=> state.lessons.verifyStripePID)
    const {
        loading: addLessonsLoading,
        success: addLessonsSuccess,
        error: addLessonsError,
        errorMessage: addLessonsErrorMessage
    } = useSelector((state) => state.lessons.addStudentLessons);

    const [instructorList, setInstructorList] = useState([]);
    const [numberOfLessons, setNumberOfLessons] = useState(0);
    const [selectedInstructor, setSelectedInstructor] = useState();
    const [isComplementary, setIsComplementary] = useState(false);
    const [stripePID, setStripePID] = useState();
    const [expiryDate, setExpiryDate] = useState();
    const [error, setError] = useState("");

    const [pages, setPages] = useState({
        page1: true,
        page2: false,
        page3: false
    });

    useEffect(() => {
        const list = studentInstructorsData?.instructors?.map((instructor) => {
            return { label: instructor?.fullName, value: instructor?._id };
        });
        setInstructorList(list);
    }, [studentInstructorsData]);

    const handleOnChange = (e) => {
        const value = e.target.value;
        setStripePID(value);
    
        if (!/^pi_/.test(value)) {
            setError("Payment ID must start with 'pi_'");
          } else {
            setError("");
          }
      
    }
    
    const handleAddLessonReason = (value) => {
        setIsComplementary(!value);
        value ? setPages({page1: false, page2: true}) : setPages({page1: false, page3: true}); 
    }

    const handleVerifyStripePID = (params) => {
        dispatch(VerifyStripePIDThunk({ pid: stripePID, countryCode }))
    }

    const handleAddLessons = (e) => {
        e.preventDefault();
        dispatch(AddLessonsThunk({ isComplementary, numberOfLessons, stripePID, studentId, instructorId: selectedInstructor, expiryDate: moment(expiryDate).utcOffset(0, true).toISOString() }));
    };

    if(verifyStripePidSuccess){
        toast.success("Payment id has been verified successfully.");
        dispatch(ResetVerifyStripePIDData());
        setPages({page2: false, page3: true});
    }

    if (addLessonsSuccess) {
        toast.success(`${numberOfLessons} lessons has been added successfully`);
        dispatch(GetStudentLessonsThunk({ studentId }));
        dispatch(ResetAddStudentLessonsData());
        setModal({ AddLesson: false });
    }

    if(verifyStripePidError){
        toast.error(verifyStripePidErrorMessage);
        dispatch(ResetVerifyStripePIDData());
    }

    if(addLessonsError){
        toast.error(addLessonsErrorMessage);
        dispatch(ResetAddStudentLessonsData());
    }

    return (
        <React.Fragment>
            {pages.page1 && (
                <Modal
                    isOpen={modal.AddLesson}
                    toggle={() => setModal({ AddLesson: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <ModalBody>
                        <div
                            onClick={(ev) => {
                                ev.preventDefault();
                                setModal({ AddLesson: false });
                            }}
                            className="close cursor_pointer"
                        >
                        <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                        <h5 className="title">Add Lesson</h5>
                        <div className="mt-4">
                            {studentInstructorLoading ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: "40vh" }}>
                                    <Spinner />
                                </div>
                            ) : (
                            <Form className="row gy-4 mt-4">
                                <Col md="6">
                                    <Button className='w-100 d-flex justify-content-center' color="primary" size="md" type="submit" onClick={(e)=> {e.preventDefault(); handleAddLessonReason(true)}}>
                                        Payment made by invoice
                                    </Button>
                                </Col>
                                <Col md="6">
                                    <Button className='w-100 d-flex justify-content-center' color="primary" size="md" type="submit" onClick={(e)=> {e.preventDefault(); handleAddLessonReason(false)}}>
                                        Compensatory lesson
                                    </Button>
                                </Col>
                            </Form>
                            )}
                        </div>
                        </div>
                    </ModalBody>
                </Modal>
            )}

            {pages.page2 && (
                <Modal
                    isOpen={modal.AddLesson}
                    toggle={() => setModal({ AddLesson: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <ModalBody>
                        <div
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ AddLesson: false });
                        }}
                        className="close cursor_pointer"
                        >
                        <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                        <h5 className="title">Add Lesson</h5>
                        <div className="mt-4">
                            {studentInstructorLoading ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: "40vh" }}>
                                    <Spinner />
                                </div>
                            ) : (
                            <Form className="row gy-4 mt-4">
                                <Col md="12">
                                    <FormGroup>
                                    <label className="form-label" htmlFor="phoneNumber">
                                        Stripe PID
                                    </label>
                                    <input
                                        type="text"
                                        id="stripePID"
                                        className="form-control"
                                        value={stripePID}
                                        name="stripePID"
                                        onChange={(e) => handleOnChange(e)}
                                        defaultValue={stripePID}
                                        placeholder="pi_xxxxxxxxxxxxxxxxxxxxxxxx"                                    
                                    />
                                    </FormGroup>
                                    {error && <small className="text-danger">{error}</small>}
                                </Col>

                                <Col size="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button color="primary" size="md" type="submit" disabled={verifyStripePidLoading} onClick={(e)=> handleVerifyStripePID(e)}>
                                            {verifyStripePidLoading ? <Spinner /> : "Next"}
                                        </Button>
                                    </li>
                                </ul>
                                </Col>
                            </Form>
                            )}
                        </div>
                        </div>
                    </ModalBody>
                </Modal>
            )}

            {pages.page3 && (
                <Modal
                    isOpen={modal.AddLesson}
                    toggle={() => setModal({ AddLesson: false })}
                    className="modal-dialog-centered"
                    size="lg"
                >
                    <ModalBody>
                        <div
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ AddLesson: false });
                        }}
                        className="close cursor_pointer"
                        >
                        <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                        <h5 className="title">Add Lesson</h5>
                        <div className="mt-4">
                            {studentInstructorLoading ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: "40vh" }}>
                                    <Spinner />
                                </div>
                            ) : (
                            <Form className="row gy-4 mt-4">
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Student Signup Date</label>
                                        <div className="form-control-wrap">
                                        <span>{ moment(studentPersonalDetail?.signupDate).format("YYYY-MM-DD")}</span>
                                        </div>
                                    </FormGroup>
                                </Col>
            
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Select number of lessons</label>
                                        <div className="form-control-wrap">
                                        <RSelect
                                            options={NumberOfLessonsOptions}
                                            placeholder="choose number of lessons"
                                            onChange={(e) => setNumberOfLessons(e.value)}
                                        />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <label className="form-label">Expiry Date</label>
                                        <Row className="gx-2">
                                        <div className="w-55">
                                            <div className="form-control-wrap">
                                            <DatePicker
                                                selected={expiryDate}
                                                onChange={(date) => setExpiryDate(date)}
                                                className="form-control date-picker"
                                            />
                                            </div>
                                        </div>
                                        </Row>
                                    </FormGroup>
                                </Col>
            
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Choose Instructor</label>
                                        <div className="form-control-wrap">
                                        <RSelect
                                            options={instructorList}
                                            name="SelectsInstructor"
                                            placeholder="Choose Instructor"
                                            onChange={(e) => setSelectedInstructor(e.value)}
                                        />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col size="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button color="primary" size="md" type="submit" disabled={addLessonsLoading} onClick={(e)=> handleAddLessons(e)}>
                                            {addLessonsLoading ? <Spinner /> : "Add Lesson"}
                                        </Button>
                                    </li>
                                    <li>
                                        <div
                                            href="#cancel"
                                            onClick={(ev) => {
                                            ev.preventDefault();
                                            setModal({ AddLesson: false });
                                            }}
                                            className="link link-light cursor_pointer"
                                        >
                                            Cancel
                                        </div>
                                    </li>
                                </ul>
                                </Col>
                            </Form>
                            )}
                        </div>
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </React.Fragment>
    )
}

export default AddLessonModal;
