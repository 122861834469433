import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// Remove In-Car Sheet
const EditInCarSheetThunk = createAsyncThunk(
    "EditInCarSheet/EditInCarSheetThunk",
    async ({ studentId, lessonSheetId, roadTestTopics, maneuverTopics, roadTestNotes, maneuverNotes, simulatedRoadTest, studentSign, instructorSign, overallGrade, lessonId, topics, notes, isMultiLessonSheet }, { rejectWithValue }) => {
        try {
        const response = await axios.post(
            `${ApiEndpoints.StudentsEndPoint.inCarSheet.EditInCarSheet}?studentId=${studentId}&`, 
            { lessonSheetId, roadTestTopics, maneuverTopics, roadTestNotes, maneuverNotes, simulatedRoadTest, studentSign, instructorSign, overallGrade, lessonId, topics, notes, isMultiLessonSheet }, 
            { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } });
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const EditInCarSheetSlice = createSlice({
    name: "EditInCarSheets",
    initialState: {
        editInCarSheetData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetEditInCarSheetData: (state, action) => {
        state.editInCarSheetData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [EditInCarSheetThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [EditInCarSheetThunk.fulfilled]: (state, action) => {
        state.editInCarSheetData = action.payload;
        state.success = true;
        state.loading = false;
        },
        [EditInCarSheetThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        state.editInCarSheetData = [];
        },
    },
});

export default EditInCarSheetSlice.reducer;

export const { ResetEditInCarSheetData } = EditInCarSheetSlice.actions;

export { EditInCarSheetThunk };
