import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// Change All Invoices Status
const ChangeAllInvoicesStatusThunk = createAsyncThunk(
    "AllInvoices/ChangeAllInvoicesStatusThunk",
    async ({ endDate }, { rejectWithValue }) => {
        try {
        const response = await axios.post(`${ApiEndpoints.Invoices.ChangeAllInvoicesStatus}`, 
        { endDate },
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });

        return response.data;
        } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong";
        return rejectWithValue(errorMessage);
        }
    }
);

const ChangeAllInvoicesStatusSlice = createSlice({
    name: "ChangeAllInvoicesStatus",
    initialState: {
        allInvoicesStatusData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetChangeAllInvoicesStatusData: (state, action) => {
        state.allInvoicesStatusData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [ChangeAllInvoicesStatusThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [ChangeAllInvoicesStatusThunk.fulfilled]: (state, action) => {
        state.allInvoicesStatusData = action.payload;
        state.success = true;
        state.loading = false;
        },
        [ChangeAllInvoicesStatusThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload;
        state.error = true;
        state.loading = false;
        },
    },
});

export default ChangeAllInvoicesStatusSlice.reducer;

export const { ResetChangeAllInvoicesStatusData } = ChangeAllInvoicesStatusSlice.actions;

export { ChangeAllInvoicesStatusThunk };
