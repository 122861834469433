import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// Download In-Car Report 
const DownloadInCarReportThunk = createAsyncThunk(
    "DownloadInCarReport/DownloadInCarReportThunk",
    async ({ studentId }, { rejectWithValue }) => {
        try {
        const response = await axios.get(`${ApiEndpoints.StudentsEndPoint.inCarSheet.DownloadInCarReport}?studentId=${studentId}`, 
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            responseType: 'blob', // This ensures the response is treated as a binary Blob
        });


        return { data: response.data, headers: response.headers }; // Return headers along with data
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const DownloadInCarReportSlice = createSlice({
    name: "DownloadInCarReport",
    initialState: {
        downloadInCarReportData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetDownloadInCarReportData: (state, action) => {
        state.downloadInCarReportData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [DownloadInCarReportThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [DownloadInCarReportThunk.fulfilled]: (state, action) => {
            const { data, headers } = action.payload; // Access data and headers

            // Extract the filename from Content-Disposition
            let filename = 'report.pdf'; // Default filename
            const contentDisposition = headers['content-disposition']; // Access headers correctly
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="(.+?)"/);
                if (match && match[1]) {
                    filename = match[1];
                }
            }
            
            // Create a Blob for the data and download
            const blob = new Blob([data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename; // Use the extracted filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            state.success = true;
            state.loading = false;
        },
        [DownloadInCarReportThunk.rejected]: (state, action) => {
        state.errorMessage = "Something went wrong. Please try again.";
        state.error = true;
        state.loading = false;
        },
    },
});

export default DownloadInCarReportSlice.reducer;

export const { ResetDownloadInCarReportData } = DownloadInCarReportSlice.actions;

export { DownloadInCarReportThunk };
