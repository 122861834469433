export const AddStudentInitialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  password: "",
  postalCode: "",
  type: "",
  instructor: "",
  paymentId: ""
};

export const AddStudentInCarReportValues = {
  fullName: "",
  address: "",
  driversLicenseNumber: "",
};

export const AddStudentNotesInitialValues = {
  fullName: "",
};

export const AddStudentRoadTestInCarSheetValues = {
  isPass: "",
  note: ""
};

export const EditInstructorProfileValues = {
  fullName: "",
  email: "",
  phoneNumber: "",
  status: "",
  country: "",
  state: "",
  address: "",
};

export const ChangePasswordInitialValues = {
  password: "",
  confirmPassword: "",
};
