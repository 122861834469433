import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import InstructorProfile from "./InstructorProfile";
import InstructorPickupLocations from "./InstructorPickupLocations";
import InstructorLessons from "./InstructorLesson";
import { Link, useParams } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component";
import { findUpper } from "../../utils/Utils";
import { Card } from "reactstrap";
import InstructorReviews from "./InstructorReview/InstructorReview";
import InstructorSchedule from "./InstructorSchedule";
import InstructorVehicleDetails from "./InstructorVehicleDetails";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import InstructorGeoFence from "./InstructorGeoFence";
import InstructorInvoices from "./InstructorInvoices";
import InstructorCalender from "./InstructorCalender/index";
import InstructorStudents from "./InstructorStudents";

const Instructors = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  const [openDetails, setOpenDetails] = useState("instructorProfile");

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <UserAvatar text={findUpper(profileName)} theme="dark" image={`${ApiEndpoints.GetImage}/instructors/${profileImage}`} />
                    <div className="user-info">
                      <span className="lead-text">{profileName}</span>
                      <span className="sub-text">{profileEmail}</span>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li onClick={() => updateSm(false)}>
                      <Link onClick={() => setOpenDetails("instructorProfile")} className={openDetails === `instructorProfile` ? "active" : ""}>
                        <Icon name="user-check" />
                        <span>Personal Information</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link onClick={() => setOpenDetails("instructorInvoices")} className={openDetails === `instructorInvoices` ? "active" : ""}>
                        <Icon name="coins" />
                        <span>Invoices</span>
                      </Link>
                    </li>

                    <li onClick={() => updateSm(false)}>
                      <Link onClick={() => setOpenDetails("instructorStudents")} className={openDetails === `instructorStudents` ? "active" : ""}>
                        <Icon name="users" />
                        <span>Instructor's Student</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link onClick={() => setOpenDetails("instructorLessons")} className={openDetails === `instructorLessons` ? "active" : ""}>
                        <Icon name="list-index" />
                        <span>Instructor's Lessons</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link onClick={() => setOpenDetails("instructorPickupLocations")} className={openDetails === `instructorPickupLocations` ? "active" : ""}>
                        <Icon name="map-pin-fill" />
                        <span>Pickup location</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link onClick={() => setOpenDetails("instructorGeoFence")} className={openDetails === `instructorGeoFence` ? "active" : ""}>
                        <Icon name="map" />
                        <span>Geo Fencing</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link onClick={() => setOpenDetails("instructorVehicleDetails")} className={openDetails === `instructorVehicleDetails` ? "active" : ""}>
                        <Icon name="file-fill" />
                        <span>Vehicle Details</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link onClick={() => setOpenDetails("instructorReviews")} className={openDetails === `instructorReviews` ? "active" : ""}>
                        <Icon name="comments" />
                        <span>Reviews</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link onClick={() => setOpenDetails("instructorCalender")} className={openDetails === `instructorCalender` ? "active" : ""}>
                        <Icon name="calendar-alt"/>
                        <span>Instructor Calender</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link onClick={() => setOpenDetails("instructorSchedule")} className={openDetails === `instructorSchedule` ? "active" : ""}>
                        <Icon name="calender-date-fill" />
                        <span>Instructor Schedule</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              {openDetails === "instructorProfile" && <InstructorProfile setProfileName={setProfileName} setProfileEmail={setProfileEmail} setProfileImage={setProfileImage} />}
              {openDetails === "instructorStudents" && <InstructorStudents />}
              {openDetails === "instructorGeoFence" && <InstructorGeoFence />}
              {openDetails === "instructorLessons" && <InstructorLessons />}
              {openDetails === "instructorPickupLocations" && <InstructorPickupLocations />}
              {openDetails === "instructorVehicleDetails" && <InstructorVehicleDetails />}
              {openDetails === "instructorReviews" && <InstructorReviews />}
              {openDetails === "instructorCalender" && <InstructorCalender />}
              {openDetails === "instructorInvoices" && <InstructorInvoices />}
              {openDetails === "instructorSchedule" && <InstructorSchedule />}
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default Instructors;
