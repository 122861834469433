import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetInCompleteInCarSheetThunk } from '../../redux/InCarSheetSlice/GetInCompleteInCarSheetSlice';
import ReactPaginate from 'react-paginate';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../components/Component';
import { Card, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import moment from 'moment';
import ShowInCarSheetDetailModal from './ShowInCarSheetDetailModal';

const InCarSheet = () => {
    const dispatch = useDispatch();

    const { loading, inCompleteInCarSheetData } = useSelector((state)=> state.inCarLessonSheet.getInCompleteInCarSheet)

    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [ selectedInCarSheetData, setSelectedInCarSheetData ] = useState();
    const [modal, setModal] = useState({
        view: false
    })

    // ********************************** Search and Pagination start ***********************************//
    const formsPerPage = 10;
    const [allforms, setAllForms] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * formsPerPage;

    const pageCount = Math.ceil(
        allforms.filter((inCarSheetData) => {
            if (onSearchText === "") {
                return inCarSheetData;
            } else if (
                inCarSheetData?.studentName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                inCarSheetData?.instructorName?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return inCarSheetData;
            }
            return false;
        }).length / formsPerPage
    );

    const displayListing = allforms
        .filter((inCarSheetData) => {
            if (onSearchText === "") {
                return inCarSheetData;
            } else if (
                inCarSheetData?.studentName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                inCarSheetData?.instructorName?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return inCarSheetData;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + formsPerPage);

    useEffect(() => {
        if(inCompleteInCarSheetData?.outstandingReports){
            const sortedInCarSheets = inCompleteInCarSheetData?.outstandingReports?.slice().sort((a, b) => {
                const nameA = a?.dateTime?.toLowerCase();
                const nameB = b?.dateTime?.toLowerCase();
                if (nameA > nameB) {
                return -1;
                }
                if (nameA < nameB) {
                return 1;
                }
                return 0;
            })
            
            setAllForms(sortedInCarSheets);
        }
    }, [inCompleteInCarSheetData?.outstandingReports]);

    useEffect(()=> {
        dispatch(GetInCompleteInCarSheetThunk());
    }, [])

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    const paginate = ({ selected }) => setPageNumber(selected);

    // ********************************** Pagination End ***********************************//

    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };

    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Head title="User Created | Admin Portal"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Incomplete In-Car Sheets</BlockTitle>
                            {/* <BlockDes className="text-soft">
                                <p>You have total {droppedStudentsData?.length} students.</p>
                            </BlockDes> */}
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                        <Spinner />
                    </div>
                ) : (
                    <Block>
                        <Card className="card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title">Incomplete In-Car Sheets</h5>
                                        </div>
                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar">
                                                <li>
                                                    <div onClick={toggle} className="d-flex btn-icon search-toggle toggle-search cursor_pointer">
                                                        <Icon name="search" style={{fontSize: "20px"}}></Icon>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                            <div className="search-content">
                                                <div
                                                    className="search-back btn-icon toggle-search cursor_pointer"
                                                    onClick={() => {
                                                        setSearchText("");
                                                        toggle();
                                                    }}
                                                >
                                                    <Icon name="arrow-left" style={{fontSize: "20px"}}></Icon>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control border-transparent form-focus-none"
                                                    placeholder="Search"
                                                    value={onSearchText}
                                                    onChange={(e) => onFilterChange(e)}
                                                />
                                                <div className="d-flex search-submit btn-icon cursor_pointer">
                                                    <Icon name="search" style={{fontSize: "20px"}}></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-inner p-0">
                                    <table className="table table-tranx">
                                        <thead>
                                            <tr className="tb-tnx-head">
                                            <th className="tb-tnx-id">
                                                    <span className="">Instructor Name</span>
                                                </th>
                                                {/* <th className="tb-tnx-id">
                                                    <span className="">Other</span>
                                                </th> */}
                                                <th className="tb-tnx-info">
                                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                        <span>Student Name</span>
                                                    </span>
                                                    <span className="tb-tnx-date d-md-inline-block d-none">
                                                        <span className="d-none d-md-block">
                                                            <span>Date</span>
                                                            <span> Time</span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th className="tb-tnx-amount is-alt">
                                                    <span className="tb-tnx-total">Lesson #</span>
                                                    {/* <span className="tb-tnx-status d-none d-md-inline-block">Road Test Time</span> */}
                                                </th>
                                                <th className="tb-tnx-id">
                                                    <span className="">Action</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayListing?.length > 0
                                                ? displayListing?.map((inCarSheetData, index) => {
                                                    return (
                                                        <tr key={inCarSheetData?._id} className="tb-tnx-item">
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{inCarSheetData?.instructorName}</span>
                                                                </div>
                                                            </td>
                                                            {/* <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{droppedStudent?.surveyData[6].value}</span>
                                                                </div>
                                                            </td> */}
                                                            <td className="tb-tnx-info">
                                                                <div className="tb-tnx-desc">
                                                                    <span className={`title tb-tnx-status `}>{ inCarSheetData?.studentName }</span>
                                                                </div>
                                                                <div className="tb-tnx-date">
                                                                    <span className={`date tb-tnx-status`}>{ moment.utc(inCarSheetData?.dateTime).format("DD/MM/yyyy") }</span>
                                                                    <span className={`date tb-tnx-status`}>{moment.utc(inCarSheetData?.dateTime).format("hh:mm A")}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-amount is-alt">
                                                                <div className="tb-tnx-total" >
                                                                    <span className={`amount`}>{ inCarSheetData?.isRoadTest ? "Road Test" : `${inCarSheetData?.studentLessonNumber} / ${inCarSheetData?.totalLessons}` }</span>
                                                                </div>
                                                                {/* <div className={` tb-tnx-status `}>{ inCarSheetData?.roadTestTime ? moment.utc(inCarSheetData?.roadTestTime).format("hh:mm A") : "" }</div> */}
                                                            </td>
                                                            <td className="tb-tnx-action">
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                        <Icon name="more-h"></Icon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right>
                                                                        <ul className="link-list-plain">
                                                                            <li>
                                                                                <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ view: true }); setSelectedInCarSheetData(inCarSheetData) }}>
                                                                                    View Detail
                                                                                </DropdownItem>
                                                                            </li>
                                                                        </ul>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-inner">
                                    {displayListing?.length > 0 ? (
                                        <ReactPaginate
                                            breakLabel="..."
                                            breakClassName="pagination-btns"
                                            onPageChange={(number) => paginate(number)}
                                            // onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={pageCount}
                                            previousLabel="<<  Prev "
                                            renderOnZeroPageCount={null}
                                            nextLabel="Next >>"
                                            previousClassName="pagination-next"
                                            nextClassName="pagination-next"
                                            activeClassName="pagination-active"
                                            pageClassName="pagination mt-0"
                                            pageLinkClassName="pagination-btns"
                                            containerClassName="pagination justify-content-start"
                                        />
                                    ) : (
                                        <div className="text-center">
                                            <span className="text-silent">No data found</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </Block>
                )}
                {modal.view && (
                    <ShowInCarSheetDetailModal modal={modal} setModal={()=> setModal(()=> setModal({ view: false })) } selectedInCarSheetData={selectedInCarSheetData} /> 
                )}

            </Content>
        </React.Fragment>
    )
}

export default InCarSheet;
