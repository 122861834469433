import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Card,
  Modal,
  ModalBody,
  DropdownItem,
  Spinner,
  FormGroup,
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  RSelect,
} from "../../components/Component";
import { useDispatch } from "react-redux";
import { GetAllStudentsThunk, ResetAllStudentData } from "../../redux/StudentsSlice/GetAllStudentSlice";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { DeleteStudentThunk, ResetDeleteStudentData } from "../../redux/StudentsSlice/DeleteStudentSlice";
import { AddStudentInitialValues, ChangePasswordInitialValues } from "../../utils/FormValues";
import { AddStudentValidationSchema, ChangePasswordValidationSchema } from "../../utils/FormValidationSchema";
import { Field, Formik, Form } from "formik";
import { AddStudentThunk, ResetAddStudentData } from "../../redux/StudentsSlice/AddStudentSlice";
import { GetInstructorListThunk } from "../../redux/InstructorSlice/GetInstructorListSlice";
import {
  ChangeStudentPasswordThunk,
  ResetChangeStudentPasswordData,
} from "../../redux/StudentsSlice/ChangeStudentPasswordSlice";
import ReactPaginate from "react-paginate";
import { GetPackagesThunk } from "../../redux/StudentsSlice/GetPackagesSlice";
import { NumberOfLessonsOptions } from "../pre-built/orders/OrderData";
const StudentsList = () => {
  const dispatch = useDispatch();

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [viewModal, setViewModal] = useState({
    addStudent: false,
    deleteStudent: false,
    changePassword: false,
  });
  const [studentId, setStudentId] = useState("");
  const [formData, setFormData] = useState({
    type: "",
    instructorData: "",
    homePickup: true,
    numberOfLessons: 10,
    countryCode: "CA",
    paymentId: "",
    packageData: {
      region: "ont",
      packageFlow: "in_car_lessons",
      packageName: "Beginner Driver Education Course (BDE)"
    }
  });

  const { loading: getInstructorListLoadting, instructorsList } = useSelector(
    (state) => state.instructors.getInstructorList
  );
  const { packagesData } = useSelector((state) => state.Students.getPackages)

  const {
    loading: AddStudentLoading,
    success: AddStudentSuccess,
    error: AddStudentError,
    errorMessage: AddStudentErrorMessage,
  } = useSelector((state) => state.Students.addStudent);

  const { loading, error, errorMessage, allStudentData } = useSelector((state) => state.Students.getAllStudentSlice);
  const {
    loading: deleteStudentLoading,
    success: deleteStudentSuccess,
    error: deleteStudentError,
    errorMessage: deleteStudentErrorMessage,
  } = useSelector((state) => state.Students.deleteStudent);

  const {
    loading: ChangeStudentPasswordLoading,
    success: ChangeStudentPasswordSuccess,
    error: ChangeStudentPasswordError,
    errorMessage: ChangeStudentPasswordErrorMessage,
  } = useSelector((state) => state.Students.changeStudentPassword);

  // ********************************** Search and Pagination start ***********************************//

  const studentsPerPage = 10;
  const [allStudentList, setAllStudentList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const pagesVisited = pageNumber * studentsPerPage;

  const pageCount = Math.ceil(
    allStudentList.filter((studentData) => {
      if (onSearchText === "") {
        return studentData;
      } else if (
        studentData.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        studentData.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
        studentData.type.toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return studentData;
      }
      return false;
    }).length / studentsPerPage
  );

  const displayListing = allStudentList
    .filter((studentData) => {
      if (onSearchText === "") {
        return studentData;
      } else if (
        studentData.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        studentData.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
        studentData.type.toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return studentData;
      }
      return false;
    })
    .slice(pagesVisited, pagesVisited + studentsPerPage);

  useEffect(() => {
    setAllStudentList(allStudentData);
  }, [allStudentData]);

  useEffect(() => {
    setPageNumber(0);
  }, [onSearchText])
  // ********************************** Pagination End ***********************************//

  useEffect(() => {
    if (instructorsList?.instructors)
      setFormData( {...formData, instructorData: instructorsList?.instructors[0] });
  }, [instructorsList]);

  useEffect(() => {
    dispatch(GetAllStudentsThunk());
    dispatch(GetInstructorListThunk());
    dispatch(GetPackagesThunk());
  }, [dispatch]);

  // Change Page
  const paginate = ({ selected }) => setPageNumber(selected);

  // ********************************* HANDLERS *************************//

  const handleAddStudent = ({ firstName, lastName, email, password, phoneNumber, postalCode, paymentId }) => {
    let numberOfLessons;
    let numberOfHours;
    let isRoadTest = false;
    if(formData?.packageData?.uuid === "a5b8f29d-2d5e-40fc-9b0e-6e7fe6a2f7d4" || formData?.packageData?.uuid === "612c5402-1f3c-4a8b-b4b6-5d2f0d4c62fd" || formData?.packageData?.uuid === "3604fc75-0b56-4d0f-bd2f-b2f9e59d065f"){
      isRoadTest = true;
    } else {
      isRoadTest = false;
    }

    if(formData?.homePickup) {
      if(formData?.packageData?.uuid === "4e7d83fa-9aeb-4e2a-a40c-f245c1d3aa78" || formData?.packageData?.uuid === "3a6bdc6d-78a1-4909-af22-8e0bce97c943" || formData?.packageData?.uuid === "8d3a19a1-76e3-4cbb-ae45-9d0c0b5f8e65" || formData?.packageData?.uuid === "a5b8f29d-2d5e-40fc-9b0e-6e7fe6a2f7d4" || formData?.packageData?.uuid === "612c5402-1f3c-4a8b-b4b6-5d2f0d4c62fd" || formData?.packageData?.uuid === "9f4e53df-ae32-491f-8017-2d0f933e9c13" ){
        numberOfLessons = 5;
        numberOfHours = 10;
      } else {
        numberOfLessons = formData?.numberOfLessons;

        numberOfHours = formData?.numberOfLessons ? 2 * formData?.numberOfLessons : 0;
      }
    } else {
      numberOfLessons = formData?.numberOfLessons;
      numberOfHours = formData?.numberOfLessons;
    }
    
    dispatch(
      AddStudentThunk({
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`,
        email,
        password,
        isRoadTest,
        phoneNumber,
        countryCode: formData?.countryCode,
        stripePID: paymentId,
        lessonhours: numberOfHours,
        postalCode,
        instructorId: formData.instructorData?._id,
        homePickup: formData?.homePickup,
        region: formData?.packageData?.region,
        packageName: formData?.packageData?.packageName,
        packageFlow: formData?.packageData?.packageFlow,
        instructorName: formData?.instructorData?.fullName,
        lessons: numberOfLessons
      })
    );
  };

  const handleDeleteStudent = (e) => {
    e.preventDefault();
    dispatch(DeleteStudentThunk({ studentId: studentId }));
  };

  const handleChangeStudentPassword = ({ password }) => {
    dispatch(
      ChangeStudentPasswordThunk({
        studentId,
        password: password,
      })
    );
  };

  // ********************************* SUCCESS API REQUEST HANDLING *************************//

  if (deleteStudentSuccess) {
    toast.success("Student has been deleted successfully.");
    dispatch(ResetDeleteStudentData());
    dispatch(GetAllStudentsThunk());
    setViewModal({ deleteStudent: false });
  }

  if (AddStudentSuccess) {
    toast.success("Student has been added successfully.");
    dispatch(ResetAddStudentData());
    dispatch(GetAllStudentsThunk());
    setViewModal({ addStudent: false });
  }

  if (ChangeStudentPasswordSuccess) {
    toast.success("Student's Password has been updated suceessfully.");
    dispatch(ResetChangeStudentPasswordData());
    dispatch(GetAllStudentsThunk());
    setViewModal({ changePassword: false });
  }

  // ********************************* ERROR HANDLING *************************//

  if (deleteStudentError) {
    toast.error(deleteStudentErrorMessage);
    dispatch(ResetDeleteStudentData());
    dispatch(GetAllStudentsThunk());
    setViewModal({ deleteStudent: false });
  }

  if (error) {
    toast.error(errorMessage);
    dispatch(ResetAllStudentData());
  }

  if (AddStudentError) {
    toast.error(AddStudentErrorMessage);
    dispatch(GetAllStudentsThunk());
    dispatch(ResetAddStudentData());
    setViewModal({ addStudent: false });
  }

  if (ChangeStudentPasswordError) {
    toast.error(ChangeStudentPasswordErrorMessage);
    dispatch(ResetChangeStudentPasswordData());
    dispatch(GetAllStudentsThunk());
    setViewModal({ changePassword: false });
  }

  return (
    <React.Fragment>
      <Head title="All Student"></Head>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <Spinner />
        </div>
      ) : (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Total Students</BlockTitle>
                <BlockDes className="text-soft">
                  <p>You have total {allStudentData?.length} students.</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <ul className="nk-block-tools-opt">
                  <li>
                    <Button color="primary" onClick={() => setViewModal({ addStudent: true })}>
                      <Icon name="plus" />
                      <span>Add Student</span>
                    </Button>
                  </li>
                </ul>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">{/* <h5 className="title">All Students</h5> */}</div>
                    <div className="card-tools mr-n1">
                      <ul className="btn-toolbar">
                        <li>
                          <Button
                            onClick={() => setonSearch(!onSearch)}
                            className="btn-icon search-toggle toggle-search"
                          >
                            <Icon name="search"></Icon>
                          </Button>
                        </li>
                      </ul>
                    </div>
                    <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                      <div className="search-content">
                        <Button
                          className="search-back btn-icon toggle-search"
                          onClick={() => {
                            setSearchText("");
                            setonSearch(!onSearch);
                          }}
                        >
                          <Icon name="arrow-left"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="form-control border-transparent form-focus-none"
                          placeholder="Search"
                          value={onSearchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <Button className="search-submit btn-icon">
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <table className="table table-tranx">
                    <thead>
                      <tr className="tb-tnx-head">
                        <th className="tb-tnx-id">
                          <span className="">#</span>
                        </th>
                        <th className="tb-tnx-info">
                          <span className="tb-tnx-desc d-none d-sm-inline-block">
                            <span>Name</span>
                          </span>
                          <span className="tb-tnx-date d-md-inline-block d-none">
                            <span className="d-none d-md-block">
                              <span>Phone</span>
                              <span>Lessons</span>
                            </span>
                          </span>
                        </th>
                        <th className="tb-tnx-amount is-alt">
                          <span className="tb-tnx-total">Package</span>
                          {/* <span className="tb-tnx-status d-none d-md-inline-block">Created </span> */}
                        </th>
                        <th className="tb-tnx-action">
                          <span>Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayListing?.length > 0
                        ? displayListing?.map((studentDetail, index) => {
                          return (
                            <tr key={studentDetail?.id} className="tb-tnx-item">
                              <td className="tb-tnx-id">
                                <a
                                  href="#ref"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <span>{pageNumber * studentsPerPage + index + 1}</span>
                                </a>
                              </td>
                              <td className="tb-tnx-info">
                                <div className="tb-tnx-desc">
                                  <span className="title">{studentDetail?.fullName}</span>
                                </div>
                                <div className="tb-tnx-date">
                                  <span className="date">{studentDetail?.phoneNumber}</span>
                                  <span className="date">{studentDetail?.lessons}</span>
                                </div>
                              </td>
                              <td className="tb-tnx-amount is-alt">
                                <div className="tb-tnx-total">
                                  <span className="amount">{studentDetail?.type}</span>
                                </div>
                              </td>
                              <td className="tb-tnx-action">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-plain">
                                      <li>
                                        <Link to={`/students/${studentDetail?._id}`}>View Details</Link>
                                      </li>
                                      <li>
                                        <DropdownItem onClick={(ev) => { ev.preventDefault(); setStudentId(studentDetail?._id); setViewModal({ deleteStudent: true }); }}>
                                          Delete Student
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem onClick={(ev) => { ev.preventDefault(); setStudentId(studentDetail?._id); setViewModal({ changePassword: true }); }}>
                                          Change Password
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                </div>
                <div className="card-inner">
                  {allStudentList.length > 0 ? (
                    <ReactPaginate
                      breakLabel="..."
                      breakClassName="pagination-btns"
                      onPageChange={(number) => paginate(number)}
                      // onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      pageCount={pageCount}
                      previousLabel="<<  Prev "
                      renderOnZeroPageCount={null}
                      nextLabel="Next >>"
                      previousClassName="pagination-next"
                      nextClassName="pagination-next"
                      activeClassName="pagination-active"
                      pageClassName="pagination mt-0"
                      pageLinkClassName="pagination-btns"
                      containerClassName="pagination justify-content-start"
                    />
                  ) : (
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </Block>
        </Content>
      )}

      {/* *********************** ADD STUDENT ************************** */}

      <Modal
        isOpen={viewModal.addStudent}
        toggle={() => setViewModal({ addStudent: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <div
            onClick={(ev) => {
              ev.preventDefault();
              setViewModal({ addStudent: false });
            }}
            className="close cursor_pointer"
          >
            <Icon name="cross-sm"></Icon>
          </div>
          <div className="p-2">
            <h5 className="title">Add Student</h5>
            {getInstructorListLoadting ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                <Spinner />
              </div>
            ) : (
              <div className="mt-4">
                <Formik
                  initialValues={AddStudentInitialValues}
                  validationSchema={AddStudentValidationSchema}
                  enableReinitialize={true}
                  onSubmit={handleAddStudent}
                >
                  {({ errors, touched }) => (
                    <Form className="row gy-4 mt-4">
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">First Name</label>
                          <Field
                            className="form-control"
                            type="text"
                            name="firstName"
                            defaultValue={formData.firstName}
                            placeholder="Enter First Name"
                          />
                          {errors.firstName && touched.firstName && <span className="invalid">{errors.firstName}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Last Name</label>
                          <Field
                            className="form-control"
                            type="text"
                            name="lastName"
                            defaultValue={formData.lastName}
                            placeholder="Enter Last Name"
                          />
                          {errors.lastName && touched.lastName && <span className="invalid">{errors.lastName}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Phone Number</label>
                          <Field
                            className="form-control"
                            type="number"
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                            defaultValue={formData.phoneNumber}
                          />
                          {errors.phoneNumber && touched.phoneNumber && (
                            <span className="invalid">{errors.phoneNumber}</span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Email</label>
                          <Field
                            className="form-control"
                            type="text"
                            name="email"
                            defaultValue={formData.email}
                            placeholder="Enter Email"
                          />
                          {errors.email && touched.email && <span className="invalid">{errors.email}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Payment Id</label>
                          <Field
                            className="form-control"
                            type="text"
                            name="paymentId"
                            defaultValue={formData.paymentId}
                            placeholder="pi_xxxxxxxxxxxxxxxxxxxxxxxx"
                          />
                          {errors.paymentId && touched.paymentId && <span className="invalid">{errors.paymentId}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Password</label>
                          <Field
                            className="form-control"
                            type="password"
                            name="password"
                            defaultValue={formData.password}
                            placeholder="Enter Password"
                          />
                          {errors.password && touched.password && <span className="invalid">{errors.password}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Postal Code</label>
                          <Field
                            className="form-control"
                            type="text"
                            name="postalCode"
                            defaultValue={formData.postalCode}
                            placeholder="Postal Code"
                          />
                          {errors.postalCode && touched.postalCode && (
                            <span className="invalid">{errors.postalCode}</span>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Is Home Pickup</label>
                          <div className="form-control-wrap">
                            <RSelect
                              name="homePickup"
                              options={[{value: true ,label: "True" }, {value: false ,label: "False" }]}
                              defaultValue={{value: true ,label: "True" }}
                              onChange={(e) => {
                                setFormData({ ...formData, homePickup: e.value });
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Instructor</label>
                          <div className="form-control-wrap">
                            <RSelect
                              name="instructorId"
                              options={instructorsList?.instructors?.map((instructor) => {
                                return { value: instructor, label: instructor?.fullName };
                              })}
                              defaultValue={{
                                value:
                                  Object.keys(instructorsList).length !== 0 ? instructorsList?.instructors[0] : "",
                                label:
                                  Object.keys(instructorsList).length !== 0
                                    ? instructorsList?.instructors[0]?.fullName
                                    : "",
                              }}
                              onChange={(e) => {
                                setFormData({ ...formData, instructorData: e.value });
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label className="form-label">Country Code</label>
                          <div className="form-control-wrap">
                            <RSelect
                              name="instructorId"
                              options={[
                                { value: "CA", label: "Canada" },
                                { value: "US", label: "United States" }
                              ]}
                              defaultValue={
                                { value: "CA", label: "Canada" }
                              }
                              onChange={(e) => {
                                setFormData({ ...formData, countryCode: e.value });
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">Package Name</label>
                          <div className="form-control-wrap">
                            <RSelect
                              name="homePickup"
                              options={
                                packagesData?.packages?.map((packageDetail)=> (
                                  {value: packageDetail, label: `${packageDetail?.name} (${packageDetail?.region === "ont" ? "Ontario" : "British Columbia"})`}
                                ))}
                              defaultValue={{value: `${packagesData?.packages[0]}` ,label: `${packagesData?.packages[0].name}` }}
                              onChange={(e) => {
                                setFormData({ ...formData, packageData: e.value, numberOfLessons: e.value?.numberOfHours });
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      {(formData?.packageData?.uuid === "7f2c08b0-4f1c-4eef-b9a6-692b4f2fd9c1" || formData?.packageData?.uuid === "b147e8e5-26bf-4a0d-8aeb-32a8a5245897") && 
                        (
                          <Col md="12">
                            <FormGroup>
                              <label className="form-label">Select number of lessons</label>
                              <div className="form-control-wrap">
                                <RSelect
                                  options={NumberOfLessonsOptions}
                                  placeholder="choose number of lessons"
                                  onChange={(e) => setFormData({ ...formData, numberOfLessons: e.value })}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        )
                      }

                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button disabled={AddStudentLoading} color="primary" size="md" type="submit">
                              {AddStudentLoading ? <Spinner /> : "Add Student"}
                            </Button>
                          </li>
                          <li>
                            <div
                              onClick={(ev) => {
                                ev.preventDefault();
                                setViewModal({ addStudent: false });
                              }}
                              className="link link-light cursor_pointer"
                            >
                              Cancel
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>

      {/* *********************** DELETE STUDENT ************************** */}

      <Modal
        isOpen={viewModal.deleteStudent}
        toggle={() => setViewModal({ deleteStudent: false })}
        className="modal-dialog-centered"
        size="md"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();

              setViewModal({ deleteStudent: false });
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h4 className="title">Delete Student</h4>
            <div className="mt-4">
              <Formik>
                <Form className="row gy-4 mt-4 mb-5">
                  <Col md="12">
                    <div>
                      <h6>Are you sure you want to delete Student?</h6>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button onClick={handleDeleteStudent} color="danger" size="md" type="submit">
                          Delete
                          <span>{deleteStudentLoading && <Spinner size="sm" />} </span>
                        </Button>
                      </li>
                      <li>
                        <div onClick={(ev) => { ev.preventDefault(); setViewModal({ deleteStudent: false }); }} className="link link-light">
                          Cancel
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* *********************** CHANGE PASSWORD ************************** */}
      <Modal
        isOpen={viewModal.changePassword}
        toggle={() => setViewModal({ changePassword: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setViewModal({ changePassword: false });
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Change Password</h5>
            {getInstructorListLoadting ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                <Spinner />
              </div>
            ) : (
              <div className="mt-4">
                <Formik
                  initialValues={ChangePasswordInitialValues}
                  validationSchema={ChangePasswordValidationSchema}
                  enableReinitialize={true}
                  onSubmit={handleChangeStudentPassword}
                >
                  {({ errors, touched }) => (
                    <Form className="row gy-4 mt-4">
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">Password</label>
                          <Field
                            className="form-control"
                            type="password"
                            name="password"
                            placeholder="Enter Password"
                          />
                          {errors.password && touched.password && <span className="invalid">{errors.password}</span>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-label">Confirm Password</label>
                          <Field
                            className="form-control"
                            type="password"
                            name="confirmPassword"
                            placeholder="Enter Confirm Password"
                          />
                          {errors.confirmPassword && touched.confirmPassword && (
                            <span className="invalid">{errors.confirmPassword}</span>
                          )}
                        </FormGroup>
                      </Col>

                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button disabled={errors ? false : true} color="primary" size="md" type="submit">
                              {ChangeStudentPasswordLoading ? <Spinner /> : "Update Password"}
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#cancel"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setViewModal({ changePassword: false });
                              }}
                              className="link link-light"
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default StudentsList;
