import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const AddInstructorEventThunk = createAsyncThunk(
    "AddInstructorEvent/AddInstructorEventThunk",
    async ({ title, startTime, endTime, instructorId, date }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.InstructorsEndPoint.AddInstructorEvent}?instructorId=${instructorId}`,
                { eventName: title, startTime, endTime, date },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data?.Event;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const AddInstructorEventSlice = createSlice({
    name: "AddInstructorEvent",
    initialState: {
        addInstructorEventData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddInstructorEventData: (state, action) => {
            state.addInstructorEventData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddInstructorEventThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddInstructorEventThunk.fulfilled]: (state, action) => {
            state.addInstructorEventData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddInstructorEventThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.addInstructorEventData = {};

        },
    },
});

export default AddInstructorEventSlice.reducer;

export const { ResetAddInstructorEventData } = AddInstructorEventSlice.actions;

export { AddInstructorEventThunk };
