import moment from 'moment'
import React from 'react'
import { Button, Card, CardBody, CardText, CardTitle, Modal, ModalBody, ModalHeader } from 'reactstrap'

const ViewInstructorDeviceDetailModal = ({ modal, setModal, selectedInstructorData }) => {
    return (
        <React.Fragment>
            <Modal
                isOpen={modal.view}
                toggle={() => setModal({ view: false })}
                className="modal-md"
            >
                <ModalHeader
                    className="d-flex justify-content-between"
                    tag="h3"
                    toggle={() => setModal({ view: false })}
                >
                    {selectedInstructorData?.fullName}
                </ModalHeader>
                <ModalBody>
                        <React.Fragment>
                            {/* <div className="d-flex justify-content-between">
                                <div>
                                    <div>
                                        <span> <span style={{fontWeight: "600", color: "#000000"}}> Instructor Name: </span> {selectedInstructorData?.fullName}</span>
                                    </div>
                                </div>
                            </div> */}
                            {selectedInstructorData?.deviceDetails
                                ?.slice()
                                ?.sort((a, b)=> new Date(b.dateAdded) - new Date(a.dateAdded))
                                ?.map((deviceDetail, index)=>{                  
                                    return ( 
                                        <Card className="card-bordered w-100 mb-1">
                                        <CardBody className="card-inner">
                                            <CardText tag="p" style={{ color: "rgb(92 106 124)", marginBottom: "0.2rem" }}>
                                            <div className="d-flex justify-content-between">
                                            <div>
                                                    <div>
                                                        <span> <span style={{fontWeight: "600", color: "#000000"}}> Device Name: </span> {deviceDetail?.deviceName}</span>
                                                    </div>
                                                    <div>
                                                        <span> <span style={{fontWeight: "600", color: "#000000"}}> Build Number: </span> {deviceDetail?.buildNumber}</span>
                                                    </div>
                                                    <div>
                                                        <span> <span style={{fontWeight: "600", color: "#000000"}}> OS Version: </span>{deviceDetail?.deviceOSVersion}  </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <span> <span style={{fontWeight: "600", color: "#000000"}}>App Version:</span> { deviceDetail?.appVersion}</span>
                                                    </div>
                                                    <div>
                                                        <span> <span style={{fontWeight: "600", color: "#000000"}}>Platform:</span> { deviceDetail?.platform}</span>
                                                    </div>
                                                    <div>
                                                        <span> <span style={{fontWeight: "600", color: "#000000"}}> Date Added: </span> {moment.utc(deviceDetail?.dateAdded).format("DD/MM/YYYY")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            </CardText>
                                            <CardTitle tag="h6"></CardTitle>
                                        </CardBody>
                                        </Card>
                                    )
                                }
                            )}
                        </React.Fragment>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default ViewInstructorDeviceDetailModal;
