import React, { useState, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Card,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
  Spinner,
  ModalHeader,
  CardTitle,
  CardText,
  CardBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Formik } from "formik";
import { toast } from "react-hot-toast";

import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  PaginationComponent,
} from "../../components/Component";
import { GetAllInstructorsThunk } from "../../redux/InstructorSlice/GetAllInstructorSlice";
import { GetAllPickupPointsThunk } from "../../redux/PickupPoints/GetAllPickupPoints";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { GetInstructorInvoiceDetailThunk } from "../../redux/InstructorSlice/GetInstructorInvoiceDetailSlice";
import { GetAllInvoicesThunk } from "../../redux/Invoices/GetAllInvoicesSlice";
import { ChangeInvoiceStatusThunk, ResetChangeInvoiceStatusData } from "../../redux/Invoices/ChangeInvoiceStatusSlice";
import { EmailInvoiceThunk, ResetEmailInvoiceData } from "../../redux/Invoices/EmailInvoiceSlice";
import { DownloadInvoiceThunk } from "../../redux/Invoices/DownloadInvoiceSlice";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import { ChangeAllInvoicesStatusThunk, ResetChangeAllInvoicesStatusData } from "../../redux/Invoices/ChangeAllInvoicesStatusSlice";

const Invoices = () => {
  const dispatch = useDispatch();

  const {
    pickupPointsData,
  } = useSelector((state) => state.pickupPoints.getAllPickupPoints);


  const { loading, invoicesData } = useSelector((
    (state)=> state.invoices.getAllInvoices
  ))

  const { 
    loading: changeStatusLoading,
    success: changeStatusSuccess,
    error: changeStatusError,
    errorMessage: changeStatusErrorMessage 
  } = useSelector(((state)=> state.invoices.changeInvoiceStatus))

  const {
    instructorInvoiceDetailData
  } = useSelector((state)=> state.instructors.getInstructorInvoiceDetail);

  const {
    loading: EmailInvoiceLoading,
    success: EmailInvoiceSuccess,
    error: EmailInvoiceError,
    errorMessage: EmailInvoiceErrorMessage
  } = useSelector((state)=> state.invoices.emailInvoice);

  const {
    loading: DownloadInvoiceLoading,
  } = useSelector((state)=> state.invoices.downloadInvoice);

  const {
    loading: ChangeAllInvoicesStatusLoading,
    success: ChangeAllInvoicesStatusSuccess,
    error: ChangeAllInvoicesStatusError,
    errorMessage: ChangeAllInvoicesStatusErrorMessage,
    allInvoicesStatusData
  } = useSelector((state)=> state.invoices.changeAllInvoicesStatus);


  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [pickupPointsList, setPickupPointsList] = useState([]);
  const [modal, setModal] = useState({
    view: false,
    add: false,
    delete: false,
    changeStatus: false,
    changeAllInvoicesStatus: false
  });

  const [selectedInvoiceId, setSelecredInvoiceId] = useState('');
  const invoicesPerPage = 10;
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);



  // ********************************** Search and Pagination start ***********************************//
  const pagesVisited = pageNumber * invoicesPerPage - invoicesPerPage;

  const pageCount = Math.ceil(
    selectedInvoices?.filter((invoiceData) => {
      if (onSearchText === "") {
        return invoiceData;
      } else if (
        invoiceData.instructorName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        // instructorData.status.toLowerCase().includes(onSearchText.toLowerCase()) ||
        // instructorData.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
        invoiceData.status.toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return invoiceData;
      }
      return false;
    }).length / invoicesPerPage
  );


  const displayInvoicesList = selectedInvoices
    ?.filter((instructorData) => {
      if (onSearchText === "") {
        return instructorData;
      } else if (
        instructorData.instructorName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        // instructorData.status.toLowerCase().includes(onSearchText.toLowerCase()) ||
        // instructorData.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorData.status.toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return instructorData;
      }
      return false;
    })
    .slice(pagesVisited, pagesVisited + invoicesPerPage);

  // ********************************** Pagination End ***********************************//

  useEffect(() => {
    dispatch(GetAllInstructorsThunk());
    dispatch(GetAllPickupPointsThunk());
    dispatch(GetAllInvoicesThunk());
  }, [dispatch]);

  useEffect(() => {
    if(invoicesData?.allInvoices){
      const data = [...invoicesData?.allInvoices[0]?.invoices]?.sort((a, b) => {
        const nameA = a?.instructorName?.split(" ")[1]?.toLowerCase();
        const nameB = b?.instructorName?.split(" ")[1]?.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })

      setSelectedDate(invoicesData?.allInvoices[0]?.endDate)

      if(invoicesData?.allInvoices){
        setSelectedInvoices(data)
      }
    }

  }, [invoicesData]);

  useEffect(() => {
    if (pickupPointsData?.pickupLocations?.length !== 0) {
      const list = pickupPointsData?.pickupLocations?.map((pickupPoint, index) => {
        return { label: `(${pickupPoint.name}) - ${pickupPoint?.address?.location}`, value: pickupPoint._id };
      });
      setPickupPointsList(list);
    }
  }, [pickupPointsData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
    setPageNumber(1);
  };

  const handleChangeSelectedDate = (invoiceData) => {
    const data = [...invoiceData?.invoices]?.sort((a, b) => {
      const nameA = a?.instructorName?.split(" ")[1]?.toLowerCase();
      const nameB = b?.instructorName?.split(" ")[1]?.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })

    setSelectedDate(invoiceData?.endDate);
    setSelectedInvoices(data);
  }

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Change Page
  const paginate = (selected) => {
    setPageNumber(selected);
  };

  const handleDownloadInvoicesCSV = async(endDate) => {
    const response = await axios.post(`${ApiEndpoints.Invoices.DownloadInvoice}?endDate=${endDate}`, 
      {},
      {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
          responseType: 'blob',
      }
    );

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    console.log('a', a)
    a.href = url;
    a.download = 'invoices.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  const handleChangeStatus = (e) => { 
    e.preventDefault();
    dispatch(ChangeInvoiceStatusThunk({ invoiceId: selectedInvoiceId }));
  }

  const handleMarkAllInvoicesPaid = (e) => {
    e.preventDefault();
    dispatch(ChangeAllInvoicesStatusThunk({ endDate: selectedDate }))
  }

  if(changeStatusSuccess){
    toast.success("Status has been changed successfully.");
    dispatch(ResetChangeInvoiceStatusData());
    dispatch(GetAllInvoicesThunk());
    setModal({changeStatus: false});
  }

  if(ChangeAllInvoicesStatusSuccess) {
    toast.success("Status has been updated successfully.");
    dispatch(ResetChangeAllInvoicesStatusData());
    dispatch(GetAllInvoicesThunk());
    setModal({ changeAllInvoicesStatus: false });
  }

  if(EmailInvoiceSuccess){
    toast.success("Invoice has been emailed successfully.");
    dispatch(ResetEmailInvoiceData());
    setModal({view: false});
  }

  if(changeStatusError){
    toast.error(changeStatusErrorMessage);
    dispatch(ResetChangeInvoiceStatusData());
    setModal({changeStatus: false});
  }

  if(ChangeAllInvoicesStatusError) {
    toast.error(ChangeAllInvoicesStatusErrorMessage);
    dispatch(ResetChangeAllInvoicesStatusData());
    setModal({ changeAllInvoicesStatus: false });
  }

  if(EmailInvoiceError){
    toast.error(EmailInvoiceErrorMessage);
    dispatch(ResetEmailInvoiceData());
    setModal({view: false});
  }

  return (
    <React.Fragment>
      {loading || DownloadInvoiceLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
          <Spinner />
        </div>
      ) : (
        <>
          <Head title="Invoices"></Head>
          <Content>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle page>Invoice</BlockTitle>
                  <BlockDes className="text-soft">
                    <p>You have total {selectedInvoices?.length} Invoices.</p>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Col className="col-5 text-right" sm="12">
                    <div className="datatable-filter">
                      <div className="d-flex justify-content-end g-2">
                        <div className="dataTables_length" id="DataTables_Table_0_length">
                          <label>
                            <span className="d-none d-sm-inline-block">Sort by Date</span>
                            <div className="form-control-select">
                              <select
                                name="DataTables_Table_0_length"
                                className="custom-select custom-select-sm form-control form-control-sm"
                                onChange={(e) => {
                                  const selectedInvoice = invoicesData?.allInvoices?.find(invoiceData => invoiceData?.endDate === e.target.value);
                                  handleChangeSelectedDate(selectedInvoice);
                                }}
                                >
                                {invoicesData?.allInvoices?.map((invoiceData, index)=>{
                                  return (
                                    <option value={invoiceData?.endDate}>{`${moment.utc(invoiceData?.endDate).subtract(14, "d").format("DD MMM") } - ${moment.utc(invoiceData?.endDate).format("DD MMM") }`}</option>
                                  )
                                })}
                              </select>
                            </div>
                            <div>
                              <Button className="mt-2" color="primary" onClick={()=> handleDownloadInvoicesCSV(selectedDate) }>Download CSV</Button>
                            </div>
                            <div>
                              <Button className="mt-2" color="primary" onClick={()=> {setModal({ changeAllInvoicesStatus: true })} }>Mark As Paid</Button>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Card className="card-bordered card-stretch">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="card-title-group">
                      <div className="card-title">
                        <h5 className="title">All Invoices</h5>
                      </div>
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar">
                          <li>
                            <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                              <Icon name="search"></Icon>
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                        <div className="search-content">
                          <Button
                            className="search-back btn-icon toggle-search"
                            onClick={() => {
                              setSearchText("");
                              toggle();
                            }}
                          >
                            <Icon name="arrow-left"></Icon>
                          </Button>
                          <input
                            type="text"
                            className="form-control border-transparent form-focus-none"
                            placeholder="Search by Instructor name"
                            value={onSearchText}
                            onChange={(e) => onFilterChange(e)}
                          />
                          <Button className="search-submit btn-icon">
                            <Icon name="search"></Icon>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner p-0">
                    <table className="table table-tranx">
                      <thead>
                        <tr className="tb-tnx-head">
                          <th className="tb-tnx-id">
                            <span className="">Instructor Name</span>
                          </th>
                          {/* <th className="tb-tnx-id">
                            <span className="">Instructor Name</span>
                          </th> */}
                          <th className="tb-tnx-info">
                          <span className="tb-tnx-desc d-none d-sm-inline-block">
                              <span>Invoice Number</span>
                            </span>
                            <span className="tb-tnx-date d-md-inline-block d-none">
                              <span className="d-none d-md-block">
                                <span>Date</span>
                                <span>Payouts</span>
                              </span>
                            </span>
                          </th>
                          
                          <th className="tb-tnx-amount is-alt">
                            <span className="tb-tnx-total">Tax</span>
                            <span className="tb-tnx-status d-none d-md-inline-block">Collect Sales Tax</span>
                          </th>
                          <th className="tb-tnx-amount is-alt">
                            <span className="tb-tnx-total">Status</span>
                            <span className="tb-tnx-status d-none d-md-inline-block">Rippling Employee Number</span>
                          </th>

                          <th className="tb-tnx-action">
                            <span>Action</span>
                          </th>
                          {/* <th className="tb-tnx-action">
                            <span>Action</span>
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {displayInvoicesList?.map((invoiceDetail)=> {
                          
                            return (
                              <tr key={1} className="tb-tnx-item">
                                  <td className="tb-tnx-id">
                                      <div onClick={(ev) => { ev.preventDefault(); }}>
                                        <span>{invoiceDetail?.instructorName}</span>
                                      </div>
                                  </td>
                                  <td className="tb-tnx-info">
                                      <div className="tb-tnx-desc">
                                        <span className="title">{invoiceDetail.invoiceNumber}</span>
                                      </div>
                                      <div className="tb-tnx-date">
                                        <span className="date">{moment.utc(invoiceDetail?.endDate).subtract(1, "day").format("MMMM DD, yyyy")}</span>
                                        <span className="date">${invoiceDetail?.state === "Ontario" ? ((invoiceDetail.invoiceTotal / 113)* 100).toFixed(2) : ((invoiceDetail.invoiceTotal / 105)* 100).toFixed(2) }</span>
                                      </div>
                                  </td>
                                  <td className="tb-tnx-amount is-alt">
                                      <div className="tb-tnx-total">
                                        <span className="amount">${!invoiceDetail?.collectsSalesTax ? (0.00).toFixed(2) :  invoiceDetail?.state === "Ontario" ? (invoiceDetail?.invoiceTotal - ((invoiceDetail.invoiceTotal / 113)* 100).toFixed(2)).toFixed(2)  : (invoiceDetail?.invoiceTotal - ((invoiceDetail.invoiceTotal / 105)* 100).toFixed(2)).toFixed(2)}</span>
                                      </div>
                                      <div className="tb-tnx-status">
                                        <span
                                              className={`badge badge-dot badge-${invoiceDetail?.collectsSalesTax ? "success" : "danger"}`}
                                          >
                                              {invoiceDetail?.collectsSalesTax ? "True": "False"}
                                          </span>
                                      </div>
                                  </td>
                                
                                  <td className="tb-tnx-amount is-alt">
                                      <div className="tb-tnx-status">
                                        <span
                                            className={`badge badge-dot badge-${invoiceDetail?.status === "Paid"
                                            ? "success"
                                            : invoiceDetail?.status === "Pending"
                                              && "danger"
                                            }`}
                                        >
                                            {invoiceDetail.status}
                                        </span>
                                      </div>
                                      <div className="tb-tnx-total">
                                        <span className="amount d-flex justify-content-center">{invoiceDetail?.instructorRipplingEmployeeNumber ? invoiceDetail?.instructorRipplingEmployeeNumber: "_"}</span>
                                      </div>
                                  </td>
                                
                                  <td className="tb-tnx-action">
                                      <UncontrolledDropdown>
                                      <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                          <Icon name="more-h"></Icon>
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        <ul className="link-list-plain">
                                          <li>
                                            <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ view: true }); dispatch(GetInstructorInvoiceDetailThunk({invoiceId: invoiceDetail?._id})); setSelecredInvoiceId(invoiceDetail?._id) }}>
                                                View Detail
                                              </DropdownItem>
                                          </li>
                                        </ul>
                                        <ul className="link-list-plain">
                                          <li>
                                            <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ changeStatus: true }); setSelecredInvoiceId(invoiceDetail?._id) }}>
                                                Mark as paid
                                              </DropdownItem>
                                          </li>
                                        </ul>
                                      </DropdownMenu>
                                      </UncontrolledDropdown>
                                  </td>
                              </tr>
                          )
                        })} 
                      </tbody>
                    </table>
                  </div>
                  <div className="card-inner">
                    {selectedInvoices?.length > 0 ? (
                      <PaginationComponent noDown itemPerPage={invoicesPerPage} totalItems={pageCount} paginate={paginate} currentPage={pageNumber} />
                    ) : (
                      <div className="text-center">
                        <span className="text-silent">No data found</span>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </Block>

            {  /*    ***************** VIEW Invoices     */}
            <Modal
              isOpen={modal.view}
              toggle={() => setModal({ view: false })}
              className="modal-md"
            >
              <ModalHeader
                className="d-flex justify-content-between"
                tag="h3"
                toggle={() => setModal({ view: false })}
              >
                Invoice Summary
              </ModalHeader>
              <ModalBody>
                {instructorInvoiceDetailData?.invoice?.map((invoiceDetail, idx)=>{
                  return (
                    <React.Fragment>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div>
                            <span> <span style={{fontWeight: "600", color: "#000000"}}> Payouts: </span> ${invoiceDetail?.state === "Ontario" ? ((invoiceDetail.invoiceTotal / 113)* 100).toFixed(2) : ((invoiceDetail.invoiceTotal / 105)* 100).toFixed(2) }</span>
                          </div>
                          <div>
                          <span> <span style={{fontWeight: "600", color: "#000000"}}> Tax: </span> ${!invoiceDetail?.collectsSalesTax ? (0).toFixed(2) :  invoiceDetail?.state === "Ontario" ? (invoiceDetail?.invoiceTotal - ((invoiceDetail.invoiceTotal / 113)* 100).toFixed(2)).toFixed(2)  : (invoiceDetail?.invoiceTotal - ((invoiceDetail.invoiceTotal / 105)* 100).toFixed(2)).toFixed(2)}</span>
                          </div>
                          <div>
                            <span> <span style={{fontWeight: "600", color: "#000000"}}> Total: </span>${(Number(invoiceDetail?.state === "Ontario" ? ((invoiceDetail.invoiceTotal / 113)* 100).toFixed(2) : ((invoiceDetail.invoiceTotal / 105)* 100).toFixed(2) ) + Number(!invoiceDetail?.collectsSalesTax ? (0).toFixed(2) :  invoiceDetail?.state === "Ontario" ? (invoiceDetail?.invoiceTotal - Number((invoiceDetail.invoiceTotal / 113)* 100).toFixed(2)).toFixed(2)  : (invoiceDetail?.invoiceTotal - ((invoiceDetail.invoiceTotal / 105)* 100).toFixed(2)).toFixed(2))).toFixed(2)}  </span>
                          </div>
                          {/* <h6> Total : ${invoiceDetail?.invoiceTotal}</h6> */}
                          <span> <span style={{fontWeight: "600", color: "#000000"}}> Invoice Period: </span> {moment.utc(invoiceDetail?.startDate).format("MMMM DD")} - {moment.utc(invoiceDetail?.endDate).subtract(1, "day").format("MMMM DD")}</span>
                          <br/>
                          <span> <span style={{fontWeight: "600", color: "#000000"}}>Payment Date:</span> { invoiceDetail?.paymentDate ? moment.utc(invoiceDetail?.paymentDate).format("DD MMM, YYYY") : "_"}</span>
                        </div>
                        <div>
                          <Button disabled={EmailInvoiceLoading} onClick={()=> dispatch(EmailInvoiceThunk({invoiceId: invoiceDetail?._id}))} color="primary" size="md">Email Pdf </Button>
                        </div>
                      </div>
                      {invoiceDetail?.invoiceList?.map((invoiceData, index)=>{                  
                      return ( 
                        <Card className="card-bordered w-100 mb-1">
                          <CardBody className="card-inner">
                            <CardText tag="p" style={{ color: "rgb(92 106 124)", marginBottom: "0.2rem" }}>
                            <div className="d-flex justify-content-between">
                                <h6>{invoiceData?.studentName}</h6>
                                <span style={{color: "#37a2d0"}} >${invoiceData?.unitPrice *invoiceData?.quantity }</span>
                              </div>
                              <div className="d-flex justify-content-between mt-3">
                                <div>
                                  <span>Description</span>
                                  <br/>
                                  <span style={{color: 'black'}}>{invoiceData?.type}</span>
                                </div>
                                <div>
                                  <span >Qty</span>
                                  <br/>
                                  <span style={{color: 'black'}}>{invoiceData?.quantity}</span>
                                </div>
                                <div>
                                  <span >Unit Price</span>
                                  <br/>
                                  <span style={{color: 'black'}}>{invoiceData?.unitPrice}</span>
                                </div>
                              </div>
                            </CardText>
                            <CardTitle tag="h6"></CardTitle>
                          </CardBody>
                        </Card>
                      )
                      })}
                    </React.Fragment>
                  )
              })
                }
              </ModalBody>
            </Modal>

            <Modal isOpen={modal.changeStatus} toggle={() => setModal({ changeStatus: false })} className="modal-dialog-centered" size="md">
                    <ModalBody>
                        <div onClick={(ev) => { ev.preventDefault(); setModal({ changeStatus: false }); }} className="close cursor_pointer">
                            <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                            <h4 className="title">Change Invoice Status</h4>
                            <div className="mt-4">
                                <Formik>
                                    <Form className="row gy-4 mt-4 mb-5">
                                        <Col md="12">
                                            <div>
                                                <span>Are you sure you want to change invoice status?</span>
                                            </div>
                                        </Col>
                                        <Col size="12">
                                            <ul className="d-flex justify-content-start flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button disabled={changeStatusLoading} onClick={handleChangeStatus} color="primary" size="md" type="submit">
                                                        <span>{changeStatusLoading ? <Spinner size="sm" /> : "Mark as paid"} </span>
                                                    </Button>
                                                </li>
                                                <li className="d-flex align-items-center cursor_pointer">
                                                    <div onClick={(ev) => { ev.preventDefault(); setModal({ changeStatus: false }); }} className="link link-light">
                                                        Cancel
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </ModalBody>
            </Modal>

            <Modal isOpen={modal.changeAllInvoicesStatus} toggle={() => setModal({ changeAllInvoicesStatus: false })} className="modal-dialog-centered" size="md">
                    <ModalBody>
                        <div onClick={(ev) => { ev.preventDefault(); setModal({ changeAllInvoicesStatus: false }); }} className="close cursor_pointer">
                            <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                            <h4 className="title">Change All Invoices Status</h4>
                            <div className="mt-4">
                                <Formik>
                                    <Form className="row gy-4 mt-4 mb-5">
                                        <Col md="12">
                                            <div>
                                                <span>Are you sure you want to change All invoices status?</span>
                                            </div>
                                        </Col>
                                        <Col size="12">
                                            <ul className="d-flex justify-content-start flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button disabled={ChangeAllInvoicesStatusLoading} onClick={handleMarkAllInvoicesPaid} color="primary" size="md" type="submit">
                                                        <span>{ChangeAllInvoicesStatusLoading ? <Spinner size="sm" /> : "Mark as paid"} </span>
                                                    </Button>
                                                </li>
                                                <li className="d-flex align-items-center cursor_pointer">
                                                    <div onClick={(ev) => { ev.preventDefault(); setModal({ changeAllInvoicesStatus: false }); }} className="link link-light">
                                                        Cancel
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </ModalBody>
            </Modal>

          </Content>
        </>
      )}
    </React.Fragment>
  );
};

export default Invoices;
