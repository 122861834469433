import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const MarkLessonAsMissedThunk = createAsyncThunk(
    "MarkLessonAsMissed/MarkLessonAsMissedThunk",
    async ({ lessonId }, { rejectWithValue }) => {
        try {
        const response = await axios.post(
            `${ApiEndpoints.LesssonsEndPoint.MarkLessonAsMissed}?lessonId=${lessonId}`,
            { },
            {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            }
        );
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const MarkLessonAsMissedSlice = createSlice({
    name: "MarkLessonAsMisseds",
    initialState: {
        lessonStatusData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetMarkLessonAsMissedData: (state, action) => {
        state.lessonStatusData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [MarkLessonAsMissedThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [MarkLessonAsMissedThunk.fulfilled]: (state, action) => {
        state.lessonStatusData = action.payload;
        state.success = true;
        state.loading = false;
        },
        [MarkLessonAsMissedThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default MarkLessonAsMissedSlice.reducer;

export const { ResetMarkLessonAsMissedData } = MarkLessonAsMissedSlice.actions;

export { MarkLessonAsMissedThunk };
