import React, { useEffect } from 'react';
import { Button, Col, Form, FormGroup, Row, Spinner } from 'reactstrap';
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../../components/Component';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import Head from '../../../layout/head/Head';
import moment from 'moment';
import { GenerateInCarSheetThunk } from '../../../redux/StudentsSlice/InCarSheetSlice/GenerateInCarSheetSlice';
import ImageContainer from '../../../components/partials/gallery/GalleryImage';
import { ApiEndpoints } from '../../../utils/ApiEndpoints';
import { DownloadInCarReportThunk } from '../../../redux/StudentsSlice/InCarSheetSlice/DownloadInCarReportSlice';

const ShowInCarReport = ({ page, setPage }) => {
    const dispatch = useDispatch();
    const { studentId } = useParams();

    const {loading, GenerateInCarSheetData} = useSelector((state)=> state.Students.InCarSheet.generateInCarSheet);

    console.log('GenerateInCarSheetData', GenerateInCarSheetData)
    const {
        loading: downloadInCarReportLoading,
    } = useSelector((state)=>  state.Students.InCarSheet.downloadInCarReport);

    useEffect(()=>{
        dispatch(GenerateInCarSheetThunk({ studentId }));
    },[dispatch, studentId]);

    const handleDownloadInCarReportPDF = (e) => {
        e.preventDefault();
        dispatch(DownloadInCarReportThunk({ studentId }))
    }
    

    return (
        <React.Fragment>
            <Head title="Student In-Car Sheet | Admin Portal" />
            <Row className="d-flex justify-content-between ">
                <Col sm="6">
                    <div className='d-flex justify-content-between'>
                        <div className=' mb-5'>
                            <Button className='btn-dim' color="primary" onClick={()=> setPage({showInCarReport: false, view: true })}> 
                                <Icon name="back-arrow-fill"/>
                                {" "} Back
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col sm="6">
                    <div className='d-flex justify-content-end'>
                        <div className=' mb-5'>
                            <Button disabled={downloadInCarReportLoading} className='btn btn-primary ' color="primary" onClick={handleDownloadInCarReportPDF}> 
                                {downloadInCarReportLoading ? <Spinner/> : "Download in-car report"}
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <BlockHead size="sm">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle page>
                            In-Car Report
                        </BlockTitle>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
            {loading ? 
                <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <Spinner />
                </div>
            : 
                <div className="mt-4">
                        <Form className="row gy-4 mt-4">
                            <Col md="12">
                                {GenerateInCarSheetData?.sheet?.licenseImage && <ImageContainer width="120" height="120" img={`${ApiEndpoints.GetImage}/studentLicenses/${GenerateInCarSheetData?.sheet?.licenseImage}`} /> }
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-label" htmlFor="fullName">
                                        Full Name
                                    </label>
                                    <input
                                        id="fullName"
                                        disabled
                                        value={GenerateInCarSheetData?.sheet?.fullName}
                                        className="form-control"
                                        name="fullName"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-label" htmlFor="address">
                                        Address
                                    </label>
                                    <input
                                        id="address"
                                        disabled
                                        value={GenerateInCarSheetData?.sheet?.address}
                                        className="form-control"
                                        name="address"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-label">Phone Number</label>
                                    <div className="form-control-wrap">
                                        <input 
                                            id="region"
                                            className="form-control"
                                            disabled
                                            value={GenerateInCarSheetData?.sheet?.phoneNumber}
                                            name="region"
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-label" htmlFor="dateOfBirth">
                                        Date of Birth
                                    </label>
                                    <input
                                        id="dateOfBirth"
                                        className="form-control"
                                        disabled
                                        value={moment.utc(GenerateInCarSheetData?.sheet?.dateOfBirth).format("DD/MM/YYYY")}
                                        name="dateOfBirth"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-label" htmlFor="driversLicenseNumber">
                                        Driver License Number
                                    </label>
                                    <input
                                        id="driversLicenseNumber"
                                        className="form-control"
                                        disabled
                                        value={GenerateInCarSheetData?.sheet?.driversLicenseNumber}
                                        name="driversLicenseNumber"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-label">Issue Date</label>
                                    <div className="form-control-wrap">
                                        <input 
                                            id="region"
                                            className="form-control"
                                            disabled
                                            value={moment.utc(GenerateInCarSheetData?.sheet?.issueDate).format("DD/MM/YYYY")}
                                            name="region"
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-label">License Type</label>
                                    <div className="form-control-wrap">
                                        <input 
                                            id="licenseType"
                                            className="form-control"
                                            disabled
                                            value={GenerateInCarSheetData?.sheet?.licenseType}
                                            name="licenseType"
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Form>
                </div>
        }

        </React.Fragment>
    )
}

export default ShowInCarReport;