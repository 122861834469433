import React from 'react';
import moment from 'moment';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const ViewILessonDetailModal = ({ modal, setModal, selectedLessonData }) => {
    console.log('selectedLessonData', selectedLessonData)
    return (
        <React.Fragment>
            <Modal
                isOpen={modal.view}
                toggle={() => setModal({ view: false })}
                className="modal-md"
            >
                <ModalHeader toggle={() => setModal({ view: false })}> Instructor Cancelled Lesson Detail </ModalHeader>
                <ModalBody>
                    <Row className="gy-3 py-1">
                        {selectedLessonData?.studentName &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Student Name</h6>
                                <p id="preview-event-description">
                                    {selectedLessonData?.studentName}
                                </p>
                            </Col>
                        }
                        {selectedLessonData?.instructorName &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Instructor Name</h6>
                                <p id="preview-event-description">
                                    {selectedLessonData?.instructorName}
                                </p>
                            </Col>
                        }
                        {selectedLessonData?.scheduledDate &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Lesson Date</h6>
                                <p id="preview-event-description">
                                    {moment.utc(selectedLessonData?.scheduledDate).format("DD-MM-YYYY")}
                                </p>
                            </Col>
                        }
                        {selectedLessonData?.scheduledDate &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Lesson Time</h6>
                                <p id="preview-event-description">
                                    {moment.utc(selectedLessonData?.scheduledDate).format("h:mm A")}
                                </p>
                            </Col>
                        }
                        {selectedLessonData?.cancellationDate &&
                            <Col sm="6">
                                <h6 className="overline-title">Cancellation Date</h6>
                                <p id="preview-event-start">
                                    {moment.utc(selectedLessonData?.cancellationDate).format("DD-MM-YYYY")}
                                </p>
                            </Col>
                        }
                        {selectedLessonData?.cancellationDate &&
                            <Col sm="6" id="preview-event-end-check">
                                <h6 className="overline-title">Cancellation Time</h6>
                                <p id="preview-event-end">
                                    {moment.utc(selectedLessonData?.cancellationDate).format("h:mm A")}
                                </p>
                            </Col>
                        }
                        {selectedLessonData?.notes &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Notes</h6>
                                <p id="preview-event-description">
                                    {selectedLessonData?.notes}
                                </p>
                            </Col>
                        }

                    </Row>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default ViewILessonDetailModal;
