import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

import { GetInstructorCancelledLessonsThunk } from '../../redux/InstructorCancelledLessonsSlice/GetAllInstructorCancelledLessonSlice';
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../components/Component';
import { Card, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import ViewILessonDetailModal from './ViewILessonDetailModal';

const InstructorCancelledLesson = () => {
    const dispatch = useDispatch();

    const { loading, instructorCancelledLessonsData } = useSelector((state)=> state.instructorCancelledLesson.getInstructorCancelledLessons)

    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [selectedLessonData, setSelectedLessonData] = useState({});
    const [modal, setModal] = useState({
        view: false
    })
    // ********************************** Search and Pagination start ***********************************//
    const formsPerPage = 10;
    const [allforms, setAllForms] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * formsPerPage;

    const pageCount = Math.ceil(
        allforms.filter((inCarSheetData) => {
            if (onSearchText === "") {
                return inCarSheetData;
            } else if (
                inCarSheetData?.studentName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                inCarSheetData?.instructorName?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return inCarSheetData;
            }
            return false;
        }).length / formsPerPage
    );

    const displayListing = allforms
        .filter((inCarSheetData) => {
            if (onSearchText === "") {
                return inCarSheetData;
            } else if (
                inCarSheetData?.studentName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                inCarSheetData?.instructorName?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return inCarSheetData;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + formsPerPage);

    useEffect(() => {
        if(instructorCancelledLessonsData.length > 0){
            const instructorCancelledData = [...instructorCancelledLessonsData];
            const sortedLessonData = instructorCancelledData?.sort((a, b) => new Date(b.cancellationDate) - new Date(a.cancellationDate));
            setAllForms(sortedLessonData);
        }
    }, [instructorCancelledLessonsData]);

    useEffect(()=> {
        dispatch(GetInstructorCancelledLessonsThunk());
    }, [dispatch])

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    const paginate = ({ selected }) => setPageNumber(selected);

    // ********************************** Pagination End ***********************************//

    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };

    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Head title="User Created | Admin Portal"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Instructor Cancelled Lessons</BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                        <Spinner />
                    </div>
                ) : (
                    <Block>
                        <Card className="card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title">Instructor Cancelled Lessons</h5>
                                        </div>
                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar">
                                                <li>
                                                    <div onClick={toggle} className="d-flex btn-icon search-toggle toggle-search cursor_pointer">
                                                        <Icon name="search" style={{fontSize: "20px"}}></Icon>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                            <div className="search-content">
                                                <div
                                                    className="search-back btn-icon toggle-search cursor_pointer"
                                                    onClick={() => {
                                                        setSearchText("");
                                                        toggle();
                                                    }}
                                                >
                                                    <Icon name="arrow-left" style={{fontSize: "20px"}}></Icon>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control border-transparent form-focus-none"
                                                    placeholder="Search"
                                                    value={onSearchText}
                                                    onChange={(e) => onFilterChange(e)}
                                                />
                                                <div className="d-flex search-submit btn-icon cursor_pointer">
                                                    <Icon name="search" style={{fontSize: "20px"}}></Icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-inner p-0">
                                    <table className="table table-tranx">
                                        <thead>
                                            <tr className="tb-tnx-head">
                                            <th className="tb-tnx-id">
                                                    <span className="">Instructor Name</span>
                                                </th>
                                                <th className="tb-tnx-id">
                                                    <span className="">Student Name</span>
                                                </th>
                                                <th className="tb-tnx-id">
                                                    <span className="">Lesson Date</span>
                                                </th>
                                                <th className="tb-tnx-id">
                                                    <span className="">Cancellation Date</span>
                                                </th>
                                                <th className="tb-tnx-id">
                                                    <span className="">Cancellation Time</span>
                                                </th>
                                                <th className="tb-tnx-id">
                                                    <span className="">Notes</span>
                                                </th>
                                                <th className="tb-tnx-action">
                                                    <span>Action</span>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayListing?.length > 0
                                                ? displayListing?.map((cancelledLessonData, index) => {
                                                    return (
                                                        <tr key={cancelledLessonData?._id} className="tb-tnx-item">
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{cancelledLessonData?.instructorName}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{cancelledLessonData?.studentName}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{moment.utc(cancelledLessonData?.scheduledDate).format("DD/MM/yyyy")}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{moment.utc(cancelledLessonData?.cancellationDate).format("DD/MM/yyyy")}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{moment.utc(cancelledLessonData?.cancellationDate).format("h:mm A")} {`(${cancelledLessonData?.timeZoneOffset === 0 ? "EST" : cancelledLessonData?.timeZoneOffset === 1 ? "CST" : cancelledLessonData?.timeZoneOffset === 2 ? "MST" : cancelledLessonData?.timeZoneOffset === 3 ? "PST" : ""})`}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{cancelledLessonData?.notes}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-action">
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                        <Icon name="more-h"></Icon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right>
                                                                        <ul className="link-list-plain">
                                                                            <li>
                                                                                <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ view: true });  setSelectedLessonData(cancelledLessonData) }}>
                                                                                    View Detail
                                                                                </DropdownItem>
                                                                            </li>
                                                                        </ul>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-inner">
                                    {displayListing?.length > 0 ? (
                                        <ReactPaginate
                                            breakLabel="..."
                                            breakClassName="pagination-btns"
                                            onPageChange={(number) => paginate(number)}
                                            // onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={pageCount}
                                            previousLabel="<<  Prev "
                                            renderOnZeroPageCount={null}
                                            nextLabel="Next >>"
                                            previousClassName="pagination-next"
                                            nextClassName="pagination-next"
                                            activeClassName="pagination-active"
                                            pageClassName="pagination mt-0"
                                            pageLinkClassName="pagination-btns"
                                            containerClassName="pagination justify-content-start"
                                        />
                                    ) : (
                                        <div className="text-center">
                                            <span className="text-silent">No data found</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </Block>
                )}

                {modal.view && <ViewILessonDetailModal modal={modal} setModal={()=> setModal({view: false})} selectedLessonData={selectedLessonData} /> }

            </Content>
        </React.Fragment>
    )
}

export default InstructorCancelledLesson;
