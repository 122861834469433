import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PaginationComponent,
} from "../../../components/Component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddSeasonalPromotionModal from "./AddSeasonalPromotionModal";
import {
  ResetUpdateSeasonalPromotionStatusData,
  UpdateSeasonalPromotionStatusThunk,
} from "../../../redux/Promotions/UpdateSeasonalPromotionStatusSlice";
import toast from "react-hot-toast";
import { GetAllPromotionsThunk } from "../../../redux/Promotions/GetAllPromotionsSlice";
import {
  DeleteSeasonalPromotionThunk,
  ResetDeleteSeasonalPromotionData,
} from "../../../redux/Promotions/DeleteSeasonalPromotionSlice";

const SeasonalPromotionTable = ({ seasonalPromotionData }) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [modal, setModal] = useState({
    addSeasonalPormotion: false,
  });

  const {
    loading: UpdatePromotionStatusLoading,
    success: UpdatePromotionStatusSuccess,
    error: UpdatePromotionStatusError,
    errorMessage: UpdatePromotionStatusErrorMessage,
  } = useSelector((state) => state.promotions.updateSeasonalPromotionStatus);

  const {
    loading: DeletePromotionLoading,
    success: DeletePromotionSuccess,
    error: DeletePromotionError,
    errorMessage: DeletePromotionErrorMessage,
  } = useSelector((state) => state.promotions.deleteSeasonalPromotion);

  if (UpdatePromotionStatusSuccess) {
    toast.success("Status has been update successfully.");
    dispatch(ResetUpdateSeasonalPromotionStatusData());
    dispatch(GetAllPromotionsThunk());
  }

  if (UpdatePromotionStatusError) {
    toast.error(UpdatePromotionStatusErrorMessage);
    dispatch(ResetUpdateSeasonalPromotionStatusData());
    dispatch(GetAllPromotionsThunk());
  }

  if (DeletePromotionSuccess) {
    toast.success("Promo code has been deleted successfully.");
    dispatch(ResetDeleteSeasonalPromotionData());
    dispatch(GetAllPromotionsThunk());
  }

  if (DeletePromotionError) {
    toast.error(DeletePromotionErrorMessage);
    dispatch(ResetDeleteSeasonalPromotionData());
    dispatch(GetAllPromotionsThunk());
  }

  return (
    <React.Fragment>
      <Head title="Promotions | Admin Portal"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Promotions</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered card-stretch">
            {UpdatePromotionStatusLoading || DeletePromotionLoading ? (
              <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "40vh" }}>
                <Spinner />
              </div>
            ) : (
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h5 className="title">Seasonal Promotions</h5>
                    </div>
                    <div className="card-tools mr-n1">
                      <ul className="btn-toolbar">
                        <li>
                          <Button onClick={() => setModal({ addSeasonalPormotion: true })} color="primary">
                            <Icon name="plus"></Icon>
                            <span>Add Promotion</span>
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <table className="table table-tranx">
                    <thead>
                      <tr className="tb-tnx-head">
                        <th className="tb-tnx-id">
                          <span className="">#</span>
                        </th>
                        <th className="tb-tnx-info">
                          <span className="tb-tnx-desc d-none d-sm-inline-block">
                            <span>Promo Code</span>
                          </span>
                          <span className="tb-tnx-date d-md-inline-block d-none">
                            <span className="d-md-none"></span>
                            <span className="d-none d-md-block">
                              {/* <span>Package Type</span> */}
                              <span>Amount</span>
                            </span>
                          </span>
                        </th>
                        <th className="tb-tnx-amount is-alt">
                          <span className="tb-tnx-total">Type</span>
                          <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                        </th>
                        <th className="tb-tnx-action">
                          <span>Action</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {seasonalPromotionData?.map((seasonalPromotion, index) => {
                        return (
                          <tr key={1} className="tb-tnx-item">
                            <td className="tb-tnx-id">
                              <a>
                                <span>{index + 1}</span>
                              </a>
                            </td>
                            <td className="tb-tnx-info">
                              <div className="tb-tnx-desc">
                                <span className="title">{seasonalPromotion.promoCode}</span>
                              </div>
                              <div className="tb-tnx-date">
                                {/* <span className="date">{seasonalPromotion.type}</span> */}
                                <span className="date">{seasonalPromotion.amount}</span>
                              </div>
                            </td>
                            <td className="tb-tnx-amount is-alt">
                              <div className="tb-tnx-total">
                                <span className="amount">{seasonalPromotion.percentage ? "%" : "$"}</span>
                              </div>
                              <div className="tb-tnx-status">
                                <span
                                  className={`badge badge-dot badge-${seasonalPromotion.valid === true
                                      ? "success"
                                      : seasonalPromotion.valid === false && "danger"
                                    }`}
                                >
                                  {seasonalPromotion.valid ? "Enabled" : "Disabled"}
                                </span>
                              </div>
                            </td>
                            <td className="tb-tnx-action">
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-plain">
                                    <li>
                                      <DropdownItem
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          dispatch(
                                            UpdateSeasonalPromotionStatusThunk({
                                              isActive: !seasonalPromotion.valid,
                                              promoId: seasonalPromotion._id,
                                            })
                                          );
                                        }}
                                      >
                                        {!seasonalPromotion.valid ? "Enable Promo Code" : "Disable Promo Code"}
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          dispatch(DeleteSeasonalPromotionThunk({ promoId: seasonalPromotion._id }));
                                        }}
                                      >
                                        Delete Promo Code
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Card>
        </Block>
      </Content>
      {modal.addSeasonalPormotion && (
        <AddSeasonalPromotionModal modal={modal.addSeasonalPormotion} setModal={setModal} />
      )}
    </React.Fragment>
  );
};

export default SeasonalPromotionTable;
