import { combineReducers } from "@reduxjs/toolkit";
import GetAllInCarSheetLessonSlice from "./GetAllInCarSheetLessonSlice";
import GetAllInCarSheetManeuversSlice from "./GetAllInCarSheetManeuversSlice";
import InitiateStudentNewInCarSheetSlice from "./InitiateStudentNewInCarSheetSlice";
import AddLessonInCarSheetSlice from "./AddLessonInCarSheetSlice";
import GetLessonSheetDetailSlice from "./GetLessonSheetDetailSlice";
import GetAllRoadTestManeuversSlice from "./GetAllRoadTestManeuversSlice";
import AddMultiLessonInCarSheetSlice from "./AddMultiLessonInCarSheetSlice";
import GenerateInCarSheetSlice from "./GenerateInCarSheetSlice";
import DownloadInCarReportSlice from "./DownloadInCarReportSlice";
import EditInCarSheetSlice from "./EditInCarSheetSlice";

export const InCarSheetReducers = combineReducers({
    getAllInCarSheetLesson: GetAllInCarSheetLessonSlice,
    getAllInCarSheetManeuvers: GetAllInCarSheetManeuversSlice,
    initiateStudentInCarReport: InitiateStudentNewInCarSheetSlice,
    addLessonInCarSheet: AddLessonInCarSheetSlice,
    getLessonSheetDetail: GetLessonSheetDetailSlice,
    getAllRoadTestManeuvers: GetAllRoadTestManeuversSlice,
    addMultiLessonInCarSheet: AddMultiLessonInCarSheetSlice,
    generateInCarSheet: GenerateInCarSheetSlice,
    downloadInCarReport: DownloadInCarReportSlice,
    editInCarSheet: EditInCarSheetSlice
});
