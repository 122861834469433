import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// Download Invoice
const DownloadPeriodInvoicesThunk = createAsyncThunk(
    "DownloadPeriodInvoices/DownloadPeriodInvoicesThunk",
    async ({ endDate }, { rejectWithValue }) => {
        try {
            console.log('endDate', endDate)
        const response = await axios.post(`${ApiEndpoints.Invoices.DownloadPeriodInvoices}?endDate=${endDate}`, 
        {},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });

        return response.data;
        } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong";
        return rejectWithValue(errorMessage);
        }
    }
);

const DownloadPeriodInvoicesSlice = createSlice({
  name: "DownloadPeriodInvoices",
  initialState: {
    downloadPeriodInvoicesData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetDownloadPeriodInvoicesData: (state, action) => {
      state.downloadPeriodInvoicesData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [DownloadPeriodInvoicesThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [DownloadPeriodInvoicesThunk.fulfilled]: (state, action) => {
      state.downloadPeriodInvoicesData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [DownloadPeriodInvoicesThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default DownloadPeriodInvoicesSlice.reducer;

export const { ResetDownloadPeriodInvoicesData } = DownloadPeriodInvoicesSlice.actions;

export { DownloadPeriodInvoicesThunk };
