import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// Get Instructor Cancelled Lessons
const GetInstructorCancelledLessonsThunk = createAsyncThunk(
    "InstructorCancelledLessons/GetInstructorCancelledLessonsThunk",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(ApiEndpoints.InstructorCancelledLessons.GetInstructorCancelledLessons, {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            const errorMessage = error?.response?.data?.message || "Something went wrong";
            return rejectWithValue(errorMessage);
        }
    }
);

const GetInstructorCancelledLessonsSlice = createSlice({
    name: "InstructorCancelledLessons",
    initialState: {
        instructorCancelledLessonsData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetInstructorCancelledLessonsData: (state, action) => {
            state.instructorCancelledLessonsData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetInstructorCancelledLessonsThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetInstructorCancelledLessonsThunk.fulfilled]: (state, action) => {
            state.instructorCancelledLessonsData = action.payload.data;
            state.success = true;
            state.loading = false;
        },
        [GetInstructorCancelledLessonsThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.instructorCancelledLessonsData = [];
        },
    },
});

export default GetInstructorCancelledLessonsSlice.reducer;

export const { ResetInstructorCancelledLessonsData } = GetInstructorCancelledLessonsSlice.actions;

export { GetInstructorCancelledLessonsThunk };
