import { combineReducers } from "@reduxjs/toolkit";
import GetStudentNotificationSlice from "./GetStudentNotificationSlice";
import UpdateStudentnotificationSlice from "./UpdateStudentnotificationSlice";
import GetAllRegionsSlice from "./GetAllRegionsSlice";
import AddRegionSlice from "./AddRegionSlice";


export const ConfigurationReducers = combineReducers({
    getStudentNotification: GetStudentNotificationSlice,
    updateStudentNotification: UpdateStudentnotificationSlice,
    getAllRegions: GetAllRegionsSlice,
    addRegion: AddRegionSlice
})