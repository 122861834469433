import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import DatePicker from "react-datepicker";
import { Popover, PopoverHeader, PopoverBody, ModalHeader, Modal, ModalBody, FormGroup, Button } from "reactstrap";
import { Col, Row, RSelect } from "../../../components/Component";
import { setDateForPicker } from "../../../utils/Utils";
import moment from "moment";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from "@reach/combobox";
import { useLoadScript } from "@react-google-maps/api";

const statusOptions = [
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Rejected" },
  { value: "Pending", label: "Pending" },
];

const EventView = (event) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, extendedProps, publicId } = event.event.event._def;
  return (
    <React.Fragment>
      <div id={publicId} onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
        {title}
      </div>{" "}
      <Popover placement="bottom" isOpen={mouseEnter} target={publicId}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{extendedProps.description}</PopoverBody>
      </Popover>
    </React.Fragment>
  );
};

const libraries = ["places"];
const options = {
  // styles: MapStyles,
  disableDefaultUI: true,
  zoomControl: false,
  componentRestrictions: {
    country: "ca",
  },
};

const StudentCalender = ({ lessonsDetail, onDelete, onEdit }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [modalState, updateModal] = useState(false);
  const [mockEvents, updateEvents] = useState(lessonsDetail);
  const [event, updateEvent] = useState({});
  const [edit, updateEditModal] = useState(false);
  const [dates, setDates] = useState({
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    updateEvents(lessonsDetail);
  }, [lessonsDetail]);

  const toggle = () => {
    updateModal(!modalState);
  };

  const toggleEdit = () => {
    updateEditModal(!edit);
  };

  const handleEventClick = (info) => {
    const event = lessonsDetail.find((item) => item.id === info.event._def.publicId);
    updateEvent(event);
    toggle();
  };

  const [loadingClass, setLoadingClass] = useState(false);
  const [mapButton, setMapButton] = useState(true);
  useEffect(() => {
    localStorage.removeItem("pick-up");
    localStorage.removeItem("lat");
    localStorage.removeItem("lng");
  }, []);

  const [latlng, setLatLng] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [pickupLocation, setPickUpLocation] = useState("");
  const [selected, SetSelected] = useState(false);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.653225, lng: () => -79.383186 },
      radius: 200 * 1000,
      // componentRestrictions: { country: "ca" },
    },
  });

  return (
    <React.Fragment>
      {mockEvents && (
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin]}
          events={mockEvents}
          eventClick={(info) => handleEventClick(info)}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "title prev,next",
            center: null,
            right: "today dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          themeSystem="bootstrap"
          height={800}
          contentHeight={780}
          eventContent={(e) => <EventView event={e} />}
          aspectRatio={3}
          editable={true}
          droppable={true}
        />
      )}

      <Modal isOpen={modalState} toggle={toggle} className="modal-md">
        <ModalHeader className={event && event.className} toggle={toggle}>
          {event && event.title}
        </ModalHeader>
        <ModalBody>
          <Row className="gy-3 py-1">
            <Col sm="6">
              <h6 className="overline-title">Start Time</h6>
              <p id="preview-event-start">{event && moment.utc(event.dateTime).format("hh:mm A")}</p>
            </Col>
            <Col sm="6" id="preview-event-end-check">
              <h6 className="overline-title">End Time</h6>
              <p id="preview-event-end">{event && moment.utc(event.dateTime).add(1, "hour").format("hh:mm A")}</p>
            </Col>
            <Col sm="6" id="preview-event-description-check">
              <h6 className="overline-title">Status</h6>
              <p id="preview-event-description">{event && event.status}</p>
            </Col>
            <Col sm="6" id="preview-event-description-check">
              <h6 className="overline-title">Type</h6>
              <p id="preview-event-description">{event && event.isRoadTest ? "Road Test" : "Regular"}</p>
            </Col>
          </Row>
          <ul className="d-flex justify-content-between gx-4 mt-3">
            <li>
              <Button
                color="primary"
                onClick={() => {
                  toggle();
                  toggleEdit();
                }}
              >
                Edit Lesson
              </Button>
            </li>
            <li>
              <Button
                color="danger"
                className="btn-dim"
                onClick={() => {
                  toggle();
                  onDelete(event && event.id);
                }}
              >
                Delete
              </Button>
            </li>
          </ul>
        </ModalBody>
      </Modal>

      <Modal isOpen={edit} toggle={toggleEdit} className="modal-md">
        <ModalHeader toggle={toggleEdit}>Edit Lesson</ModalHeader>
        <ModalBody>
          <form className="form-validate is-alter">
            <Row className="gx-4 gy-3">
              <Col size="12">
                <FormGroup>
                  <label className="form-label" htmlFor="event-title">
                    Select Location
                  </label>
                  <div className="form-control-wrap">
                    <Combobox
                      onSelect={async (address) => {
                        setValue(address, false);
                        clearSuggestions();
                        SetSelected(true);
                        setLoadingClass(true);
                        try {
                          const results = await getGeocode({ address });
                          const { lat, lng } = await getLatLng(results[0]);
                          setLatLng({
                            latitude: lat,
                            longitude: lng,
                          });
                          setPickUpLocation(address);
                        } catch (error) {
                          console.log(error);
                        } finally {
                          setLoadingClass(false);
                          setMapButton(false);
                        }
                      }}
                    >
                      <div className="map-input-search" style={{ position: "relative", zIndex: 1 }}>
                        <ComboboxInput
                          // value={value}
                          style={{
                            // background: "#f2f4f5",
                            // borderRadius: "5px",
                            borderColor: "black",
                            padding: "15px 12px",
                            width: "100%",
                            textAlign: "left",
                            fontFamily: "inherit",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "18px",
                            color: "#071d27",
                            outline: "none",
                            // border: "none",
                          }}
                          className="map-input form-control"
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          disabled={!ready}
                          autocomplete={false}
                          placeholder="Enter an address"
                        />
                        {/* <img
                          onClick={clearInput}
                          className="map-input-exit"
                          src={process.env.PUBLIC_URL + "/images/exit.svg"}
                          alt="exit"
                        /> */}
                      </div>
                      <ComboboxPopover>
                        <div className={selected ? "display-none" : "container"}>
                          <div className="row">
                            <div className="no-padding-mobile">
                              <ComboboxList
                                className="map-box map-box-suggestions"
                                style={{
                                  background: "#ffffff",
                                  border: "1px solid #dfe5e8",
                                  boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.14)",
                                  borderRadius: "4px",
                                  padding: "15px",
                                  marginLeft: "15px",
                                  position: "relative",
                                  zIndex: "50000",
                                }}
                              >
                                {status === "OK" &&
                                  data.map(({ place_id, description }) => (
                                    <>
                                      <ComboboxOption
                                        style={{ cursor: "pointer" }}
                                        key={place_id}
                                        value={description}
                                      />
                                      <hr />
                                    </>
                                  ))}
                              </ComboboxList>
                            </div>
                          </div>
                        </div>
                      </ComboboxPopover>
                    </Combobox>
                  </div>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label className="form-label">Start Date &amp; Time</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={new Date(event.start)}
                          onChange={(date) => updateEvent({ ...event, start: setDateForPicker(date) })}
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={dates.startTime}
                          onChange={(date) => setDates({ ...dates, startTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Row>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <label className="form-label">End Date &amp; Time</label>
                  <Row className="gx-2">
                    <div className="w-55">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={new Date(event.end)}
                          onChange={(date) => updateEvent({ ...event, end: setDateForPicker(date) })}
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <div className="w-45">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={dates.endTime}
                          onChange={(date) => setDates({ ...dates, endTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Row>
                </FormGroup>
              </Col>
              <Col size="12">
                <FormGroup>
                  <label className="form-label">Status</label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={statusOptions}
                      //ref={register({ required: true })}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col size="12">
                <ul className="d-flex justify-content-between gx-4 mt-1">
                  <li>
                    <Button type="submit" color="primary">
                      Update Lesson
                    </Button>
                  </li>
                  <li>
                    <Button color="danger" className="btn-dim" onClick={toggleEdit}>
                      Discard
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default StudentCalender;
