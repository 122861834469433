import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import { GetAllPromotionsThunk } from "../../redux/Promotions/GetAllPromotionsSlice";
import LMSPromotionsTable from "./LMSPromotions/LMSPromotionsTable";
import ReferralPromotionTable from "./ReferralPromotions/ReferralPromotionsTable";
import SeasonalPromotionTable from "./SeasonalPromotions/SeasonalPromotionTable";
import Head from "../../layout/head/Head";

document.title = "Promotions - Kruzee Admin";

const Promotions = () => {
  const dispatch = useDispatch();

  const { loading, AllPromotionsData } = useSelector((state) => state.promotions.allPromotions);
  useEffect(() => {
    dispatch(GetAllPromotionsThunk());
  }, []);
  return (
    <React.Fragment>
      <Head title="Promotions | Admin Portal"></Head>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <Spinner />
        </div>
      ) : (
        <>

          <SeasonalPromotionTable seasonalPromotionData={AllPromotionsData?.seasonal} />
          <ReferralPromotionTable refferalPromotionData={AllPromotionsData?.inCarReferral} />
          <LMSPromotionsTable lmsRefferalPromotionData={AllPromotionsData?.lmsReferral} />

        </>
      )}
    </React.Fragment>
  );
};

export default Promotions;
